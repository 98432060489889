import HelpTextIcon from "../../../Components/InformationIcon";

function VirtueMartStepThree({ configuration, handleChange }) {
    return (
        <div>
            <h3 className="text-2xl font-semibold mb-8">
                Steg 3 -{" "}
                <span className="text-lg font-medium text-accent">
                    {!configuration.apiKey
                        ? "Aktivera integration"
                        : "Konfigurera åtkomst"}
                </span>
            </h3>

            <div className="flex items-start">
                <div className="flex flex-col space-y-8 w-1/2">
                    <ul className="text-md font-medium list-disc list-outside pl-6 space-y-4 mb-20">
                        <li>
                            För att vi ska kunna identifiera vilken server och
                            webshop som anropar oss, behöver vi få er Bas-URL
                            nedan.
                        </li>
                        <div>
                            <div className="flex items-start">
                                <label
                                    htmlFor="identifier"
                                    className="block font-medium"
                                >
                                    Bas-URL
                                </label>
                                <HelpTextIcon infoText="Ange Bas-URL till er webshop. Exempel: https://exempelföretag.se" />
                            </div>
                            <input
                                type="text"
                                name="identifier"
                                value={configuration.identifier}
                                onChange={handleChange}
                                className="w-3/5 p-2 h-10 border border-gray-300 rounded-md"
                                required
                            />
                        </div>
                        <li>
                            Om er server kräver autentisering för att vi ska
                            kunna skicka tillbaka trackinginformation, stödjer
                            vi basic auth med användarnamn och lösenord. Om
                            ingen autentisering krävs kan fälten lämnas tomma.
                        </li>
                        <div>
                            <div className="flex items-start">
                                <label
                                    htmlFor="username"
                                    className="block font-medium"
                                >
                                    Användarnamn
                                </label>
                                <HelpTextIcon infoText="Detta fält är endast nödvändigt om autentisering krävs för att nå er VirtueMart-server." />
                            </div>
                            <input
                                type="text"
                                name="username"
                                value={configuration.username}
                                onChange={handleChange}
                                className="w-3/5 p-2 h-10 border border-gray-300 rounded-md"
                            />
                        </div>
                        <div>
                            <div className="flex items-start">
                                <label
                                    htmlFor="password"
                                    className="block font-medium"
                                >
                                    Lösenord
                                </label>
                                <HelpTextIcon infoText="Detta fält är endast nödvändigt om autentisering krävs för att nå er VirtueMart-server." />
                            </div>
                            <input
                                type="password"
                                name="password"
                                value={configuration.password}
                                onChange={handleChange}
                                className="w-3/5 p-2 h-10 border border-gray-300 rounded-md"
                            />
                        </div>
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default VirtueMartStepThree;
