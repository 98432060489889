import { SUPPORTED_CARRIERS } from "../../../constants/carriers/const";

const SupportedCarriers = ({ extension }) => {
    return (
        <div
            className="flex items-start mr-10 mt-4 p-4 rounded-md"
            style={{
                backgroundColor: "#F4EFE8",
            }}
        >
            <img
                src="/images/icons/warning-icon.svg"
                alt="Warning icon"
                className="w-6 h-6 mr-3 mt-1"
            />
            <div>
                <p className="text-md font-medium">
                    Observera att "Shipping methods" i {extension} måste ha
                    exakt samma namn som det vi listar här för att valet av
                    transportör ska kunna fungera.
                </p>
                <ul className="list-disc list-outside pt-2 pl-6">
                    {SUPPORTED_CARRIERS.carriers.map((method, index) => (
                        <li key={index} className="text-md font-medium">
                            <span className="font-semibold">
                                {method.value}
                            </span>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default SupportedCarriers;
