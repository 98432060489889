import HelpTextIcon from "../../../Components/InformationIcon";

function AbicartStepOne({ configuration, handleChange }) {
    return (
        <div>
            <h3 className="text-2xl font-semibold mb-8">
                Steg 1 -{" "}
                <span className="text-lg font-medium text-accent">
                    Inställningar
                </span>
            </h3>

            <div className="flex items-start">
                <div className="flex flex-col space-y-8 w-1/2">
                    <ul className="text-md font-medium list-disc list-outside pl-6 space-y-4 mb-20">
                        <li>Logga in på ditt Abicart-konto.</li>
                        <div className="flex items-start">
                            <li>Ange ert Shop ID i fältet nedan.</li>
                            <HelpTextIcon infoText="Du hittar ditt Shop ID i sidomenyn på ditt Abicart-konto. Det är markerat i rött på bild 1." />
                        </div>
                        <div>
                            <label
                                htmlFor="identifier"
                                className="block font-medium"
                            >
                                Shop ID
                            </label>
                            <input
                                type="text"
                                name="identifier"
                                value={configuration.identifier}
                                onChange={handleChange}
                                className="w-3/5 p-2 mb-3 h-10 border border-gray-300 rounded-md"
                                required
                            />
                        </div>
                        <li>
                            Gå till "Inställningar" → "Externa Kopplingar" i
                            sidomenyn.
                        </li>
                        <li>
                            Klicka på "Redigera" på rutan för "Abicart API".
                        </li>
                        <li>
                            Klicka på "Lägg till" för att skapa en ny Auth
                            Token.
                        </li>

                        <div className="flex items-start">
                            <li>
                                Kopiera värdet på din token och klistra in det i
                                fältet nedan.
                            </li>
                            <HelpTextIcon infoText="Token visas först när den skapas och är markerad i rött på bild 2." />
                        </div>

                        <div>
                            <label
                                htmlFor="authToken"
                                className="block font-medium"
                            >
                                Token
                            </label>
                            <input
                                type="text"
                                name="authToken"
                                value={configuration.authToken}
                                onChange={handleChange}
                                className="w-3/5 p-2 mb-3 h-10 border border-gray-300 rounded-md"
                                required
                            />
                        </div>
                    </ul>
                </div>

                <div className="ml-10 mr-10 space-y-6">
                    <img
                        src={`/images/extensions/abicart/shopid.png`}
                        alt="Shop ID"
                        className="object-contain w-[32rem] h-[32rem] max-w-sm"
                    />
                    <img
                        src={`/images/extensions/abicart/authtoken.png`}
                        alt="Auth Token"
                        className="object-contain w-[32rem] h-[32rem] max-w-3xl"
                    />
                </div>
            </div>
        </div>
    );
}

export default AbicartStepOne;
