import axios from "axios";
import InputMultiToggle from "components/Input/MultiToggle";
import InputTextSuggestions from "components/Input/TextSuggestions";
import Input from "components/OldInput";
import { incoTerms } from "constants/IncoTerms";
import Context from "context/Global";
import React, {
    forwardRef,
    useContext,
    useEffect,
    useImperativeHandle,
    useRef,
    useState,
} from "react";
import Api from "services/Api/Api";
import styled from "styled-components/macro";

let Container = styled.div`
    padding: 16px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 2px;
    background: #fff;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    > .section {
        width: 100%;
        box-sizing: border-box;
    }
`;

let Title = styled.div`
    font-size: 24px;
    margin-bottom: 8px;
    color: rgba(0, 0, 0, 0.6);
`;

const validationMessages = {
    required: "Fältet är obligatoriskt",
};

function QuotationInformation(props, ref) {
    const context = useContext(Context);
    const informationRef = useRef();
    const [customsRequired, setCustomsRequired] = useState(
        props.customsRequired,
    );
    const [selectedCurrency, setSelectedCurrency] = useState("SEK");
    const [showDangerousGoodsType, setShowDangerousGoodsType] = useState(false);
    const [descriptionSuggestions, setDescriptionSuggestions] = useState([]);
    const cancelSearchTokenRef = useRef();
    const searchTimeoutRef = useRef();
    const [information, setInformation] = useState({
        description: props.information?.description,
        reference: props.information?.reference || "",
        dangerousGoods: props.information?.dangerousGoods || {
            active: false,
        },
    });

    const [informationObjectDescription, setInformationObjectDescription] =
        useState(getInformationObjectDescription());

    useEffect(() => {
        setCustomsRequired(props.customsRequired);
    }, [props.customsRequired]);

    useEffect(() => {
        if (
            props.isBatchView &&
            (props.information?.description ||
                props.information?.description === "")
        ) {
            const updatedInfo = {
                description: props.information?.description,
                reference: props.information?.reference || "",
                dangerousGoods: props.information?.dangerousGoods || {
                    active: false,
                },
            };
            informationRef.current.set(updatedInfo);
            setInformation(updatedInfo);
        }
    }, [props.information?.description]);

    useEffect(() => {
        if (
            information.dangerousGoods &&
            information.dangerousGoods.active &&
            (context?.order?.sender?.countryCode !== "SE" ||
                context?.order?.receiver?.countryCode !== "SE")
        ) {
            setShowDangerousGoodsType(true);
        } else {
            setShowDangerousGoodsType(false);
        }

        setInformationObjectDescription(getInformationObjectDescription());
    }, [information.dangerousGoods, context.order]);

    useEffect(() => {
        if (!props.isBatchView) {
            const [firstPackage] = context?.order?.packages || [];
            const { description } = firstPackage || {};
            if (description) {
                const updatedInfo = { ...information, description };
                informationRef.current.set(updatedInfo);
                setInformation(updatedInfo);
            }
        }
    }, [context?.order?.packages]);

    useEffect(() => {
        if (props.onChange && information) {
            props.onChange({
                description: information.description,
                reference: information.reference,
                dangerousGoods: information.dangerousGoods,
            });
        }
        setInformationObjectDescription(getInformationObjectDescription());
    }, [information, customsRequired]);

    useEffect(() => {
        searchPackageDescriptions(information.description);
    }, [information.description]);

    const validate = () => {
        return informationRef.current.validate();
    };

    const clear = () => {
        const emptyData = {
            description: "",
            reference: "",
            customsTerms: incoTerms.dap.code,
            customsValue: {
                amount: null,
                currency: "SEK",
            },
            dangerousGoods: {
                active: false,
                type: null,
            },
        };
        setInformation(emptyData);
        informationRef.current.set(emptyData);
    };

    useImperativeHandle(ref, () => {
        return {
            validate: validate,
            clear: clear,
        };
    });

    async function searchPackageDescriptions(searchstring) {
        if (cancelSearchTokenRef.current) {
            cancelSearchTokenRef.current.cancel();
        }
        cancelSearchTokenRef.current = axios.CancelToken.source();
        clearTimeout(searchTimeoutRef.current);
        searchTimeoutRef.current = setTimeout(async () => {
            const descriptions = await Api.getCustomerPackageDescription({
                cancelToken: cancelSearchTokenRef.current?.token,
                customerId: context.user.customer.id,
                searchstring,
            });

            setDescriptionSuggestions(
                descriptions.map((text) => {
                    return {
                        title: text,
                    };
                }),
            );
        }, 200);
    }

    function onDescriptionFocus() {
        searchPackageDescriptions(information.description);
    }

    function selectPackageDescription(value) {
        setInformation((information) => {
            informationRef.current.set({
                ...information,
                description: value,
            });
            return {
                ...information,
                description: value,
            };
        });
    }

    useEffect(() => {
        setInformationObjectDescription(getInformationObjectDescription());
    }, [descriptionSuggestions]);

    useEffect(() => {
        if (!information.dangerousGoods.active) {
            setInformation((information) => ({
                ...information,
                dangerousGoods: {
                    ...information.dangerousGoods,
                    limitedQuantity: false,
                },
            }));
        }
        if (
            context?.order?.sender?.countryCode !== "SE" ||
            context?.order?.receiver?.countryCode !== "SE"
        ) {
            setInformation((information) => ({
                ...information,
                dangerousGoods: {
                    ...information.dangerousGoods,
                    limitedQuantity: false,
                },
            }));
        }
    }, [
        context?.order?.sender?.countryCode,
        context?.order?.receiver?.countryCode,
        information.dangerousGoods?.active,
    ]);

    function getInformationObjectDescription() {
        function hideLimitedQuantity() {
            if (
                !context.user.customer.settings?.limitedQuantity &&
                !props?.batch
            ) {
                return true;
            }

            if (information.dangerousGoods.active) {
                if (
                    context?.order?.sender?.countryCode === "SE" &&
                    context?.order?.receiver?.countryCode === "SE"
                ) {
                    return false;
                }
                return true;
            }

            return true;
        }

        const objectDefinition = {
            description: {
                type: InputTextSuggestions,
                onFocus: onDescriptionFocus,
                suggestions: descriptionSuggestions,
                onSelect: selectPackageDescription,
                blurOnSelect: true,
                title: "Godsbeskrivning",
                required: true,
                maxLength: 30,
                placeholder: "",
                messages: {
                    required: "Fältet är obligatoriskt",
                    maxLength: "Fältet innehåller för många tecken",
                    minLength: "Fältet innehåller för få tecken",
                },
            },
            reference: {
                type: "text",
                title: "Referens",
                placeholder: "",
                maxLength: 35,
            },
            dangerousGoods: {
                type: "list",
                columns: 2,
                hidden:
                    context.user.customer.settings.dangerousGoods !== true ||
                    props?.isBatchView,
                object: {
                    active: {
                        type: InputMultiToggle,
                        title: "Farligt gods",
                        options: [
                            {
                                title: "Ja",
                                value: true,
                            },
                            {
                                title: "Nej",
                                value: false,
                            },
                        ],
                    },
                    limitedQuantity: {
                        hidden: hideLimitedQuantity(),
                        type: "checkbox",
                        title: "Limited quantity",
                        required: false,
                    },
                },
            },
        };

        if (context.user.customer.settings.goodsRowDescription) {
            delete objectDefinition.description;
        }

        return objectDefinition;
    }

    return (
        <div>
            <Container>
                <form className="section information" autoComplete="off">
                    <Input
                        ref={informationRef}
                        messages={validationMessages}
                        onChange={setInformation}
                        value={information}
                        type="list"
                        columns="2"
                        object={informationObjectDescription}
                    />
                </form>
            </Container>
            {customsRequired && !props?.isBatchView && (
                <div className="pt-4">
                    <Container>
                        <p className="py-4 font-medium">
                            Tull-villkor och tullvärde hanteras efter du har
                            valt transporttjänst.
                        </p>
                    </Container>
                </div>
            )}
        </div>
    );
}

export default forwardRef(QuotationInformation);
