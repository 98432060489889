import moment from "moment";
import { isStockholmPostalCode } from "./isStockholmPostalCode";

/**
 * Get time definite options for a specific date.
 * @param {string} pickupDate - The pickup date.
 * @param {string} deliveryDate - The delivery date.
 * @param {object} order - The order object.
 * @param {object} option - The option object.
 * @returns {object[]} An array of time definite options.
 * @example
 * getTimeDefiniteOptions("2022-01-01", "2022-01-02", order, option);
 * // [
 *   { from: "07:00", to: "09:00", price: 1400, value: "2022-01-01T07:00:00+01:00" },
 *  { from: "09:00", to: "11:00", price: 1080, value: "2022-01-01T09:00:00+01:00" },
 * { from: "11:00", to: "13:00", price: 1300, value: "2022-01-01T11:00:00+01:00" },
 * { from: "13:00", to: "15:00", price: 2700, value: "2022-01-01T13:00:00+01:00" }
 * ]
 */
export const getTimeDefiniteOptions = (
    pickupDate,
    deliveryDate,
    order,
    option,
) => {
    const dateToCompare =
        option.name === "timeDefiniteLoading" ? pickupDate : deliveryDate;

    const postalCode =
        option.name === "timeDefiniteLoading"
            ? order.sender.postalCode
            : order.receiver.postalCode;

    const timeRanges = isStockholmPostalCode(postalCode, "SE")
        ? [
              { from: "07:00", to: "09:00", price: 1830 },
              { from: "09:00", to: "11:00", price: 1300 },
              { from: "11:00", to: "13:00", price: 2050 },
              { from: "13:00", to: "15:00", price: 3240 },
          ]
        : [
              { from: "07:00", to: "09:00", price: 1400 },
              { from: "09:00", to: "11:00", price: 1080 },
              { from: "11:00", to: "13:00", price: 1300 },
              { from: "13:00", to: "15:00", price: 2700 },
          ];

    const timeDefiniteIntervals = timeRanges.map((range) => ({
        ...range,
        value: moment(dateToCompare)
            .set("hour", parseInt(range.from))
            .toISOString(true),
    }));

    return timeDefiniteIntervals.filter((interval) => {
        const currentTime = moment().format("HH:mm");
        if (dateToCompare === moment().format("YYYY-MM-DD")) {
            return currentTime < interval.to;
        }
        return true;
    });
};
