import styled from "styled-components";

export const Title = styled.div`
    font-size: 24px;
    margin-bottom: 8px;
    color: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 1rem;
`;

export const ExceptionsModal = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding: 1rem;

    > .container {
        border-radius: 1rem;
        background: #fff;
        padding: 1rem;
        max-width: 50vw;
        max-height: 70vh;
        overflow: auto;
        box-shadow: 0 4px 1rem 0px rgba(0, 0, 0, 0.2);

        > .title {
            text-align: center;
            font-size: 2rem;
        }

        > .subtitle {
            text-align: center;
        }

        > ul {
        }
    }
`;

export const Card = styled.div`
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    margin-bottom: 8px;
    min-height: 60px;
    background: #fff;
    padding: 1rem;

    &.green {
        background: #dfd;
        > .input {
            display: flex;
            gap: 1rem;
            align-items: center;
        }
    }

    &.insurance {
        display: flex;
        flex-direction: column;

        > .toggle {
            display: flex;
            align-items: center;
            height: 38px;
            width: 100%;

            > .label {
                padding-left: 1rem;
            }

            > .note {
                padding-left: 1rem;
                font-size: 0.9em;
            }
        }

        > .information {
            display: flex;
            align-items: center;
            margin-top: 1rem;

            > .label {
                padding-left: 1rem;
            }

            > .note {
                padding-left: 1rem;
                font-size: 0.9em;
            }

            > .exceptions {
                margin-left: auto;
                cursor: pointer;
                width: 80px;
                color: #555;
                display: flex;
                flex-direction: column;
                align-items: center;
                font-size: 10px;
                text-align: center;

                &:hover {
                    color: #aaa;
                }

                > svg {
                    flex-shrink: 0;
                    width: 18px;
                    height: 18px;
                    margin-bottom: 4px;
                }
            }
        }

        > .reminder {
            display: flex;
            align-items: center;
            margin-top: 1rem;
            font-style: italic;
        }

        > .content {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            margin-top: 1rem;

            > .packageRows {
                display: grid;
                grid: 1fr / repeat(3, 1fr);
                grid-gap: 1rem;
                width: 100%;
            }

            > .label {
                font-weight: bold;
            }

            > .price {
                margin-top: 1rem;

                > b {
                    > .Loader {
                        height: 1rem;
                        width: 1rem;
                        display: inline-block;
                        align-self: center;

                        > svg {
                            width: 100%;
                            height: 100%;
                        }
                    }
                }
            }
        }
    }
    .subtitle {
        font-size: 1rem;
        font-weight: bold;
        margin-top: 1rem;
    }

    .textarea {
        max-width: 400px;
    }

    &.pickup {
        display: flex;
        flex-direction: column;

        > .toggle {
            display: flex;
            align-items: center;

            > .label {
                padding-right: 1rem;
            }

            > .note {
                padding-left: 1rem;
                font-size: 0.9em;
            }
        }

        .datetime {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            grid-gap: 0 1rem;
            width: fit-content;
            margin-top: 1rem;

            > label {
                font-weight: bold;
            }
        }

        .localtime {
            padding: 1rem 0;
            font-size: 0.9rem;
        }
    }

    &.delivery {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        align-items: flex-start;

        > .option {
            width: 100%;
            display: flex;
            align-items: flex-start;
            gap: 1rem;
            padding: 1rem;
            border: 1px solid rgba(0, 0, 0, 0.1);
            border-radius: 1rem;
            transition: all 0.3s ease;

            &.is-selected {
                border: 1px solid rgba(0, 150, 0, 0.5);
            }

            > .checkbox {
                min-width: 32px;
                padding-top: 2px;
            }

            > .text {
                > .Input {
                    margin-top: 1rem;
                    display: inline-block;
                }

                > .accessCode {
                    display: flex;
                    gap: 1rem;
                    align-items: center;
                    margin-top: 1rem;
                }
            }
        }

        > .instructions {
            > .textarea {
                width: 400px;
                max-width: 100%;
            }
        }

        .sub-title {
            font-size: 1rem;
            font-weight: bold;
            display: flex;
            justify-content: space-between;

            > .help {
                width: 20px;
                height: 20px;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                color: #999;
                transition: all 0.3s ease;

                &:hover {
                    color: #333;
                }
            }
        }
    }

    > .servicepoint {
        max-width: 400px;
    }

    &.confirm {
        > .summary {
            font-size: 1.2rem;
            margin-bottom: 1rem;
        }
    }

    .dangerousGoods {
        display: flex;
        flex-direction: column;
        padding-bottom: 1rem;
        margin-bottom: 1rem;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);

        > .title {
            font-weight: bold;
            font-size: 1.2rem;
        }

        > .text {
            > p {
                margin: 0 0 1rem 0;
            }
        }
        > .confirm {
            display: flex;
            align-items: center;

            > .text {
                margin-left: 1rem;
            }
        }
    }

    > .wrapper {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 400px;

        > .title {
            color: rgba(0, 0, 0, 0.5);
            margin: 16px 0;

            &:first-child {
                margin-top: 0;
            }
        }

        > .pickupDate {
            display: flex;
            align-items: center;

            > .suggestion {
                margin-left: 1em;
            }
        }

        > .notifications {
            > .description {
                display: flex;
                align-items: center;

                > .text {
                    margin-right: 16px;
                }
            }

            > .option {
                display: flex;
                flex-direction: row;
                align-items: center;
                padding: 8px;

                > .title {
                    font-weight: normal;
                    padding-left: 8px;
                }
            }
        }

        > .input {
            width: 100%;
            max-width: 500px;
        }

        > .message {
            > .text {
                font-weight: bold;
                font-size: 14px;
                color: #900;
            }

            > .details {
                font-size: 12px;
                color: #900;
            }
        }
    }
    > .pickup {
        padding: 1rem 0;

        > .toggle {
            display: flex;
            align-items: center;

            > .label {
                padding-right: 1rem;
            }

            > .note {
                padding-left: 1rem;
                font-size: 0.9em;
            }
        }

        > .title {
            color: rgba(0, 0, 0, 0.5);
            margin: 16px 0;

            &:first-child {
                margin-top: 0;
            }
        }

        > .desiredPickupTimes {
            display: flex;
            flex-direction: row;
            align-items: center;

            > div {
                padding: 0 8px;
            }
        }
    }

    > .commercialInvoiceReminder {
        display: flex;
        align-items: center;
        padding-bottom: 1rem;

        > .text {
            margin-left: 16px;
        }
    }

    > .options {
        > .option {
            width: 100%;
            display: flex;
            align-items: top;
            margin-bottom: 1rem;

            &:last-child {
                margin-bottom: 0;
            }

            > .checkbox {
                padding: 0.5rem 2rem 0 1rem;
            }

            > .text {
                > .title {
                    font-size: 1.2rem;
                    font-weight: bold;
                }
                > .description {
                }
            }
            > .price {
                margin-left: auto;
                white-space: nowrap;
                padding-left: 2rem;
                text-align: right;
                font-size: 1.6rem;
                font-weight: bold;
            }
        }
    }

    &.palletExchange {
        > .text {
            > .input {
                margin-top: 1rem;
                max-width: 200px;
            }
        }
    }
`;
