import React from "react";
import ProductDates from "./ProductDates";
import { getTransporterLogo } from "helpers/Logos";
import { getProductName } from "helpers/Services";
import { hasCheaperOptions, isObjectEmpty } from "utils/batchTableUtils";
import { getDeliveryDateInformation } from "utils/dateHelpers";

const ProductContent = ({ order, nonEUOrder }) => {
    const { deliveryDate, deliveryDateInterval } = getDeliveryDateInformation(
        order.data.selectedQuote?.deliveryDate,
        order.data.selectedQuote?.deliveryDateInterval,
    );
    return (
        <div>
            {nonEUOrder ? (
                <div className="max-w-md h-[47px] flex-row justify-center items-start gap-1 inline-flex">
                    <div className="text-brown text font-normal flex flex-wrap">
                        <span>Denna order ska skickas utanför EU</span>
                        <span>och behöver hanteras separat.</span>
                    </div>
                </div>
            ) : (
                <div className="flex rounded-md gap-3 items-center">
                    {order?.data.selectedQuote?.service ? (
                        <>
                            <img
                                draggable={false}
                                alt="Transporter logo"
                                className="w-28 h-10 border-r border-gray-300 pr-3"
                                src={`/images/icons/${getTransporterLogo(
                                    order?.data.selectedQuote.service.name,
                                )}`}
                            />
                            <div className="flex flex-col items-start">
                                <div className="flex text-sm flex-wrap">
                                    <span className="font-bold">
                                        {isObjectEmpty(
                                            order.data.selectedQuote.price,
                                        ) && `Direktbokning `}
                                        {getProductName(
                                            order.data.selectedQuote,
                                        )}
                                        {!isObjectEmpty(
                                            order.data.selectedQuote.price,
                                        ) && `:${"  "}`}
                                    </span>
                                    {order.data.selectedQuote.price?.total ? (
                                        <span className="pl-1">
                                            {
                                                order.data.selectedQuote.price
                                                    ?.total
                                            }{" "}
                                            {
                                                order.data.selectedQuote.price
                                                    ?.unit
                                            }
                                        </span>
                                    ) : (
                                        <span>
                                            Bokningen sker utan prisindikation
                                        </span>
                                    )}
                                </div>
                                <ProductDates
                                    pickupDate={
                                        order.data.selectedQuote.pickupDate
                                    }
                                    service={
                                        order.data.selectedQuote.service.name
                                    }
                                    deliveryDate={deliveryDate}
                                    deliveryDateInterval={deliveryDateInterval}
                                    requestedPickupDate={
                                        order.data.requestedPickupDate
                                    }
                                />
                                {hasCheaperOptions(order) && (
                                    <span className="text-green-700 font-semibold">
                                        Billigare alternativ finns!
                                    </span>
                                )}
                            </div>
                        </>
                    ) : (
                        <div className="text-left">
                            <span className="text-brown">
                                <p>Ingen tjänst vald.</p>
                                <p>Sök pris för att välja tjänst.</p>
                            </span>
                            {order.data.requestedPickupDate ? (
                                <p>
                                    Önskad upphämtningsdag:{" "}
                                    {order.data.requestedPickupDate}
                                </p>
                            ) : (
                                <p className="text-neutral-800 text-sm font-normal">
                                    Ingen upphämtning vald
                                </p>
                            )}
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default ProductContent;
