import React, { Component } from "react";
import styled from "styled-components";
import Input from "../";

let Container = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    > .item {
        margin-bottom: 8px;
        width: 100%;

        > label {
            display: inline-flex;
            align-items: center;
            font-size: 0.8em;
            font-weight: bold;
            padding: 4px;

            > .required {
                margin-left: 0.2em;
                color: rgba(220, 10, 10, 1);
            }

            > .helpText {
                padding-left: 8px;
                display: flex;
                justify-content: center;
                align-items: center;
                position: relative;

                > .text {
                    position: absolute;
                    opacity: 0;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    text-align: left;
                    background: #fff;
                    padding: 0.4em;
                    border-radius: 0.4em;
                    box-shadow: 0 1px 8px 5px rgba(0, 0, 0, 0.1);
                    bottom: 100%;
                    color: #555;
                    font-weight: normal;
                    transition: opacity 0.3s ease;
                    white-space: normal;
                    pointer-events: none;
                }

                > .icon {
                    width: 12px;
                    height: 12px;
                    padding: 4px;
                    box-sizing: content-box;
                }

                &:hover {
                    > .text {
                        opacity: 1;
                        pointer-events: all;
                    }
                }
            }
        }

        &.is-hidden {
            display: none;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    &.columns-2 {
        > .item {
            width: 50%;
            padding-right: 16px;

            &:nth-child(2n) {
                padding-right: 0;
            }
        }
    }
`;
class InputList extends Component {
    constructor(props) {
        super(props);

        let refs = {};
        let defaultValue = props.value || {};

        Object.keys(props.object).forEach((key) => {
            refs[key] = React.createRef();
            if (typeof defaultValue[key] === "undefined") {
                defaultValue[key] = props.object[key].value;
            }
        });

        this.state = {
            value: defaultValue || {},
            object: props.object || {},
            refs: refs,
        };

        this.value = this.value.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    value() {
        let value = {};
        Object.keys(this.state.object).forEach((key) => {
            if (this.state.refs[key] && this.state.refs[key].current) {
                value[key] = this.state.refs[key].current.value();
            }
        });

        return value;
    }

    set(value) {
        Object.keys(this.state.object).forEach((key) => {
            if (
                typeof value !== "undefined" &&
                typeof value[key] !== "undefined" &&
                this.state.refs[key] &&
                this.state.refs[key].current
            ) {
                this.state.refs[key].current.set(value[key]);
            }
        });
    }

    setValidationMessage(options) {
        Object.keys(this.state.object).forEach((key) => {
            if (key === options.key) {
                this.state.refs[key].current.setValidationMessage(
                    options.message,
                );
            }
        });
    }

    empty() {
        Object.keys(this.state.object).forEach((key) => {
            if (this.state.refs[key].current) {
                this.state.refs[key].current.empty();
            }
        });
    }

    onChange() {
        this.setState(
            {
                value: this.value(),
            },
            () => {
                if (typeof this.props.onChange === "function") {
                    this.props.onChange(this.state.value);
                }
            },
        );
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        return {
            object: nextProps.object || {},
        };
    }

    validate() {
        let valid = true;
        Object.keys(this.state.object).map((key) => {
            if (!this.state.refs[key].current.validate()) {
                valid = false;
            }
            return null;
        });
        return valid;
    }

    focus(key) {
        if (this.state.refs[key] && this.state.refs[key].current) {
            this.state.refs[key].current.focus();
        }
    }

    getHelpTextElement(helpText) {
        if (!helpText) {
            return null;
        }
        return (
            <div className="helpText">
                <div className="text">{helpText}</div>
                <svg
                    className="icon"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                >
                    <path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm1.25 17c0 .69-.559 1.25-1.25 1.25-.689 0-1.25-.56-1.25-1.25s.561-1.25 1.25-1.25c.691 0 1.25.56 1.25 1.25zm1.393-9.998c-.608-.616-1.515-.955-2.551-.955-2.18 0-3.59 1.55-3.59 3.95h2.011c0-1.486.829-2.013 1.538-2.013.634 0 1.307.421 1.364 1.226.062.847-.39 1.277-.962 1.821-1.412 1.343-1.438 1.993-1.432 3.468h2.005c-.013-.664.03-1.203.935-2.178.677-.73 1.519-1.638 1.536-3.022.011-.924-.284-1.719-.854-2.297z" />
                </svg>
            </div>
        );
    }

    render() {
        return (
            <Container
                className={
                    this.props.columns ? "columns-" + this.props.columns : ""
                }
            >
                {Object.keys(this.state.object).map((key, index) => {
                    let {
                        required,
                        title,
                        type,
                        autoComplete,
                        options,
                        helpText,
                        messages,
                        ...otherProps
                    } = this.state.object[key];
                    if (messages) {
                        otherProps.messages = messages;
                    } else if (this.props.messages) {
                        otherProps.messages = this.props.messages;
                    }
                    return (
                        <div
                            className={
                                "item" +
                                (this.state.object[key].hidden
                                    ? " is-hidden"
                                    : "")
                            }
                            key={key}
                        >
                            {this.state.object[key].title && (
                                <label>
                                    {this.state.object[key].title}
                                    {this.state.object[key].required ? (
                                        <span className="required">*</span>
                                    ) : (
                                        ""
                                    )}
                                    {this.getHelpTextElement(
                                        this.state.object[key].helpText,
                                    )}
                                </label>
                            )}
                            {this.state.object[key].subTitle && (
                                <span className="text-gray-900 font-thin text-xs leading-none flex flex-row ml-1 pb-2">
                                    {this.state.object[key].subTitle}
                                </span>
                            )}
                            <Input
                                tabIndex="0"
                                ref={this.state.refs[key]}
                                {...otherProps}
                                required={
                                    required && !this.state.object[key].hidden
                                }
                                title={title}
                                autoComplete={autoComplete}
                                type={type}
                                options={options}
                                name={key}
                                value={this.state.value[key]}
                                onChange={(value) => {
                                    this.onChange();
                                }}
                            />
                        </div>
                    );
                })}
            </Container>
        );
    }
}

export default InputList;
