import React from "react";
import { SUPPORTED_CARRIERS } from "../../../../../constants/carriers/const";
import SupportedCarriers from "../../../Components/SupportedCarriers";
import Integrations from "../../../../../constants/integrations";

function WoocommerceStepThree({ configuration, handleChange }) {
    return (
        <div>
            <h3 className="text-2xl font-semibold mb-8">
                Steg 3 -{" "}
                <span className="text-lg font-medium text-accent">
                    Inställningar för importering av ordrar och val av
                    transportör
                </span>
            </h3>

            <div className="flex items-start">
                <div className="flex flex-col space-y-8 w-1/2">
                    <ul className="text-md font-medium list-disc list-outside pl-6 space-y-4 mb-20">
                        <li>Välj hur du vill importera ordrar:</li>

                        <div className="flex items-center space-x-4">
                            <input
                                type="radio"
                                id="importAllOrders"
                                name="importFilteringEnabled"
                                value={false}
                                checked={!configuration.importFilteringEnabled}
                                onChange={(e) =>
                                    handleChange({
                                        target: {
                                            name: "importFilteringEnabled",
                                            value: e.target.value === "true",
                                        },
                                    })
                                }
                                className="form-radio w-5 h-5 rounded-full"
                                style={{
                                    accentColor: "rgba(76, 80, 175, 1)",
                                }}
                            />
                            <label
                                htmlFor="importAllOrders"
                                className="text-md font-medium"
                            >
                                Importera alla ordrar
                            </label>
                        </div>

                        <div className="flex items-center space-x-4">
                            <input
                                type="radio"
                                id="filterOrders"
                                name="importFilteringEnabled"
                                value={true}
                                checked={configuration.importFilteringEnabled}
                                onChange={(e) =>
                                    handleChange({
                                        target: {
                                            name: "importFilteringEnabled",
                                            value: e.target.value === "true",
                                        },
                                    })
                                }
                                className="form-radio w-5 h-5 rounded-full"
                                style={{
                                    accentColor: "rgba(76, 80, 175, 1)",
                                }}
                            />
                            <label
                                htmlFor="filterOrders"
                                className="text-md font-medium"
                            >
                                Importera endast de ordrar som skickas med någon
                                av transportörerna som Zendr erbjuder
                            </label>
                        </div>

                        <li>
                            Välj om du vill tillåta systemet att förvälja
                            transportör till den importerade ordern:
                        </li>
                        <div className="flex space-x-4">
                            <input
                                type="checkbox"
                                id="shippingRateFiltering"
                                checked={configuration.shippingRateFiltering}
                                onChange={(e) =>
                                    handleChange({
                                        target: {
                                            name: "shippingRateFiltering",
                                            value: e.target.checked,
                                        },
                                    })
                                }
                                className="form-checkbox w-7 h-7"
                                style={{
                                    accentColor: "rgba(76, 80, 175, 1)",
                                }}
                            />
                            <label
                                htmlFor="quotationFiltering"
                                className="text-md font-medium"
                            >
                                Ja, tillåt.
                                <p
                                    className={`text-sm pt-2 ${
                                        configuration.shippingRateFiltering
                                            ? "text-normal"
                                            : "text-accent"
                                    }`}
                                >
                                    Systemet väljer det billigaste alternativet
                                    av de transportörer Zendr erbjuder. Det
                                    kommer fortfarande gå att ändra transportör
                                    innan bokning.
                                </p>
                            </label>
                        </div>
                    </ul>
                </div>

                <div className="ml-10 mr-10 space-y-6">
                    <img
                        src={`/images/extensions/woocommerce/shippingmethods.png`}
                        alt="Exempel på fraktmetoder"
                        className="object-contain w-[32rem] h-[32rem] max-w-3xl"
                    />
                </div>
            </div>

            {(configuration.importFilteringEnabled ||
                configuration.shippingRateFiltering) && (
                <SupportedCarriers extension={"WooCommerce"} />
            )}
        </div>
    );
}

export default WoocommerceStepThree;
