import Context from "context/Global";
import moment from "moment";
import React, { useContext } from "react";
import { getTimeDefiniteOptions } from "utils/timeDefiniteOptions";

const TimeDefiniteComponent = ({ option, updateAddition }) => {
    const context = useContext(Context);
    const quotation = context.quotation;
    const order = context.order;
    const pickupDate = quotation.pickupDate;
    const deliveryDate = quotation.deliveryDate;

    const timeDefiniteOptions = getTimeDefiniteOptions(
        pickupDate,
        deliveryDate,
        order,
        option,
    );

    return (
        <div className="flex flex-col ">
            <div className="text-sm font-bold">
                Välj tid för{" "}
                {option.name === "timeDefiniteLoading"
                    ? "lastning"
                    : "lossning"}
            </div>
            <div className="flex flex-col gap-1 flex-wrap">
                {timeDefiniteOptions.map((interval) => {
                    const datetime = interval.value;

                    return (
                        <div className="flex" key={interval.from}>
                            <input
                                type="radio"
                                name={option.name}
                                checked={
                                    option.parameters?.datetime === datetime
                                }
                                onChange={() => {
                                    updateAddition({
                                        name: option.name,
                                        value: option.value,
                                        parameters: {
                                            datetime: datetime,
                                        },
                                    });
                                }}
                            />
                            <div className="ml-2">
                                {interval.from} - {interval.to}:{" "}
                                <span className="font-bold">
                                    {interval.price} kr
                                </span>
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default TimeDefiniteComponent;
