import React from "react";
import { formatDateString, isValidDate } from "utils/batchTableUtils";

const ProductDates = ({
    pickupDate,
    deliveryDate,
    requestedPickupDate,
    service,
    blackText,
    deliveryDateInterval,
}) => {
    const isValid =
        isValidDate(pickupDate) &&
        (isValidDate(deliveryDate) || deliveryDateInterval);

    const textColor = blackText ? "text-black" : "text-brown";

    const formatDeliveryDateInteral = (deliveryDateInterval) => {
        if (deliveryDateInterval) {
            const { min, max } = deliveryDateInterval;
            return `${min}${min !== max ? `-${max}` : ""}`;
        }
        return null;
    };

    function renderDates() {
        if (requestedPickupDate) {
            if (!isValid) {
                return <p className={textColor}>Estimerat datum saknas</p>;
            } else {
                return (
                    <div className="flex flex-col whitespace-normal items-start text-sm">
                        {service.toLowerCase() === "postnord" ? (
                            <span className="text-brown flex flex-col text-left">
                                <p>Ingen upphämtning</p>
                                <p>Upphämtning bokar du via huvudmenyn.</p>
                            </span>
                        ) : (
                            <>
                                <span className="flex text-left gap-1">
                                    {pickupDate ? (
                                        <>
                                            <p>Est. Upphämtning:</p>
                                            <p>
                                                {formatDateString(pickupDate)}
                                            </p>
                                        </>
                                    ) : (
                                        <>
                                            <p>Önskad Upphämtning:</p>
                                            <p>
                                                {formatDateString(
                                                    requestedPickupDate,
                                                )}
                                            </p>
                                        </>
                                    )}
                                </span>
                                {deliveryDate && (
                                    <span className="flex text-left gap-1">
                                        <p>Est. Leverans:</p>
                                        <p>{formatDateString(deliveryDate)}</p>
                                    </span>
                                )}
                                {!deliveryDate && deliveryDateInterval && (
                                    <span className="flex text-left gap-1">
                                        <p>Est. Leverans:</p>
                                        <p>
                                            {formatDeliveryDateInteral(
                                                deliveryDateInterval,
                                            ) ?? ""}{" "}
                                            dagar
                                        </p>
                                    </span>
                                )}
                            </>
                        )}
                    </div>
                );
            }
        } else {
            return (
                <>
                    <div className="flex flex-col whitespace-normal items-start text-sm">
                        <p className={textColor}>Ingen upphämtning</p>
                        {service.toLowerCase() === "postnord" && (
                            <span className="text-brown flex justify-start flex-wrap text-left gap-1">
                                Upphämtning bokar du via huvudmenyn.
                            </span>
                        )}
                    </div>
                </>
            );
        }
    }

    return <>{renderDates()}</>;
};

export default ProductDates;
