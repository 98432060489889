import axios from "axios";
import InputCheckbox from "components/Input/Checkbox";
import InputNumber from "components/Input/Number";
import Loader from "components/Loader/Loader";
import Input from "components/OldInput";
import { isMemberOfEU } from "constants/countryList";
import Context from "context/Global";
import { priceParser } from "helpers/StringParser";
import moment from "moment";
import React, { useContext, useEffect, useRef, useState } from "react";
import ReactTooltip from "react-tooltip";
import Api from "services/Api/Api";
import Confirmation from "../../Confirmation";
import DangerousGoods from "./DangerousGoods";
import toast from "react-hot-toast";
import { useNotificationSettings } from "../hooks/useNotificationSettings";
import AdditionalOption from "./AdditionalOption";
import { Title, Card, ExceptionsModal } from "./StyledComponents";

function Component({ service, bookOrder, order, receiverPays }) {
    const context = useContext(Context);
    const [price, setPrice] = useState(null);
    const [servicePoints, setServicePoints] = useState([]);
    const [message, setMessage] = useState(null);
    const [loadingNewQuote, setLoadingNewQuote] = useState(false);
    const [confirmDangerousGoods, setConfirmDangerousGoods] = useState(false);
    const [desiredPickupDate, setDesiredPickupDate] = useState(null);
    const [pickupInstructions, setPickupInstructions] = useState("");
    const [pickupLocation, setPickupLocation] = useState(null);
    const [selectedServicePointIndex, setSelectedServicePointIndex] =
        useState(null);
    const [selectedServicePoint, setSelectedServicePoint] = useState(null);
    const [deliveryInstructions, setDeliveryInstructions] = useState("");
    const [desiredPickupStartTime, setDesiredPickupStartTime] = useState();
    const [desiredPickupEndTime, setDesiredPickupEndTime] = useState();
    const [desiredPickupHours, setDesiredPickupHours] = useState([]);
    const [suggestedPickup, setSuggestedPickup] = useState(null);
    const [suggestedDelivery, setSuggestedDelivery] = useState(null);
    const [showCommercialInvoiceReminder, setShowCommercialInvoiceReminder] =
        useState(false);
    const [
        commercialInvoiceReminderAccepted,
        setCommercialInvoiceReminderAccepted,
    ] = useState(false);

    const [includePickup, setIncludePickup] = useState(
        getDefaultPickupSelection(),
    );
    const [additionalOptions, setAdditionalOptions] = useState(null);
    const [thermoPrice, setThermoPrice] = useState("");
    const [initialPriceLoaded, setInitialPriceLoaded] = useState(false);
    const [shipmentOptions, setShipmentOptions] = useState([
        {
            title: "Leverans utan kvittens",
            text: "Att leverera utan kvittens innebär att försändelsen levereras oavsett om mottagaren är på plats eller inte. Går ej att kombinera med försäkring eller farligt gods.",
            parameter: "deliveryWithoutProofOfDelivery",
            value: false,
            disabled: false,
        },
    ]);
    const pickupInstructionsRef = useRef();
    const deliveryInstructionsRef = useRef();
    const dangerousGoodsRef = useRef();
    const pickupStartTimeRef = useRef();
    const pickupEndTimeRef = useRef();
    const pickupStartDateRef = useRef();
    const [invalidDate, setInvalidDate] = useState(false);
    const cancelSearchTokenRef = useRef();
    const [addInsurance, setAddInsurance] = useState(false);
    const [insuranceValue, setInsuranceValue] = useState();
    const [insuranceTermsAccepted, setInsuranceTermsAccepted] = useState(false);
    const insuranceInputRef = useRef();
    const [showExceptionModal, setShowExceptionModal] = useState(false);
    const [showHomeDeliveryPopup, setShowHomeDeliveryPopup] = useState(false);
    const [palletExchangeCount, setPalletExchangeCount] = useState(null);
    const [servicePointDeliveryOption, setServicePointDeliveryOption] =
        useState("SERVICE_POINT");

    const { notificationSettings, setNotificationSettings } =
        useNotificationSettings(order, ["onTender"]);

    const thermoWarm = "thermoWarm";

    const isPrivateParcel =
        (service.productCode === "DHLPaket" ||
            service.productCode === "DHLPaket2") &&
        context.order.receiver.contact.private;

    const hasDeliveryWithPOD = shipmentOptions.some(
        (shipmentOption) =>
            shipmentOption.parameter === "deliveryWithoutProofOfDelivery" &&
            shipmentOption.value === false,
    );

    function getDefaultPickupSelection() {
        if (context.quotation.service.productCode === "DHLServicePointReturn") {
            return false;
        } else {
            return !context.user.customer.fixedPickups.find((pickup) => {
                return (
                    pickup.carrier === "DHL" &&
                    (pickup.data.weekdays.mon ||
                        pickup.data.weekdays.tue ||
                        pickup.data.weekdays.wed ||
                        pickup.data.weekdays.thu ||
                        pickup.data.weekdays.fri)
                );
            });
        }
    }

    useEffect(() => {
        setDesiredPickupDate(
            moment(context.quotation.pickupDate || undefined).format(
                "YYYY-MM-DD",
            ),
        );
        if (pickupStartDateRef.current) {
            pickupStartDateRef.current.set(
                moment(context.quotation.pickupDate || undefined).format(
                    "YYYY-MM-DD",
                ),
            );
        }
        if (showServicePointPicker()) {
            let shipment = context.getApiOrderObject();
            Api.getServicePoints({
                destination: shipment.route.to,
                service: service,
            })
                .then((servicePoints) => {
                    setServicePoints(servicePoints);
                })
                .then(() => {
                    // setLoading(false);
                })
                .catch(() => {
                    toast("Det gick inte att hämta servicepunkter");
                });
        }
    }, []);

    useEffect(() => {
        if (order) {
            if (
                order.customs ||
                order.customsInvoice ||
                (isMemberOfEU(order.sender.countryCode) &&
                    isMemberOfEU(order.receiver.countryCode))
            ) {
                setShowCommercialInvoiceReminder(false);
            } else {
                setShowCommercialInvoiceReminder(true);
            }
        }
    }, [order]);

    useEffect(() => {
        if (servicePoints && servicePoints[0]) {
            setSelectedServicePointIndex(servicePoints[0].index);
        }
    }, [servicePoints]);

    useEffect(() => {
        let startHour = 6;
        let startMinute = 0;
        let endHour = 19;
        let endMinute = 0;

        if (suggestedPickup) {
            // suggestedPickup is a date string. So why these statements?
            if (suggestedPickup.startDateTime) {
                startHour = parseInt(
                    moment(suggestedPickup.startDateTime).format("HH"),
                );
                startMinute = parseInt(
                    moment(suggestedPickup.startDateTime).format("mm"),
                );
            }
            if (suggestedPickup.endDateTime) {
                endHour = parseInt(
                    moment(suggestedPickup.endDateTime).format("HH"),
                );
                endMinute = parseInt(
                    moment(suggestedPickup.endDateTime).format("mm"),
                );
            }

            if (suggestedPickup === moment().format("YYYY-MM-DD")) {
                startHour = parseInt(moment().add(5, "minutes").format("HH"));
                startMinute = parseInt(moment().add(5, "minutes").format("mm"));
                if (startMinute < 30) {
                    startMinute = 30;
                } else if (startMinute >= 30) {
                    startMinute = 0;
                    startHour++;
                }
            }
        } else {
            if (desiredPickupDate === moment().format("YYYY-MM-DD")) {
                startHour = parseInt(moment().add(5, "minutes").format("HH"));
                startMinute = parseInt(moment().add(5, "minutes").format("mm"));
                if (startMinute < 30) {
                    startMinute = 30;
                } else if (startMinute >= 30) {
                    startMinute = 0;
                    startHour++;
                }
            }
        }

        let hours = [];

        for (var i = startHour; i <= endHour; i++) {
            if (i === startHour) {
                if (startMinute === 0) {
                    hours.push({
                        title: ("0" + i).slice(-2) + ":00",
                        value: ("0" + i).slice(-2) + ":00",
                    });
                }
                if (startMinute <= 30) {
                    hours.push({
                        title: ("0" + i).slice(-2) + ":30",
                        value: ("0" + i).slice(-2) + ":30",
                    });
                }
            } else if (i === endHour) {
                if (endMinute === 0) {
                    hours.push({
                        title: ("0" + i).slice(-2) + ":00",
                        value: ("0" + i).slice(-2) + ":00",
                    });
                }
                if (endMinute >= 30) {
                    hours.push({
                        title: ("0" + i).slice(-2) + ":30",
                        value: ("0" + i).slice(-2) + ":30",
                    });
                }
            } else {
                hours.push({
                    title: ("0" + i).slice(-2) + ":00",
                    value: ("0" + i).slice(-2) + ":00",
                });
                hours.push({
                    title: ("0" + i).slice(-2) + ":30",
                    value: ("0" + i).slice(-2) + ":30",
                });
            }
        }

        setDesiredPickupHours(hours);
    }, [suggestedPickup, desiredPickupDate]);

    useEffect(() => {
        if (!desiredPickupHours || !desiredPickupHours.length) {
            return;
        }

        const startTime = desiredPickupHours[0].value;
        const endTime = desiredPickupHours[desiredPickupHours.length - 1].value;

        if (pickupStartTimeRef.current) {
            if (desiredPickupStartTime) {
                if (desiredPickupStartTime < startTime) {
                    pickupStartTimeRef.current.set(startTime);
                    setDesiredPickupStartTime(startTime);
                }
            } else {
                pickupStartTimeRef.current.set(startTime);
                setDesiredPickupStartTime(startTime);
            }
        } else {
            setDesiredPickupStartTime(startTime);
        }
        if (pickupEndTimeRef.current) {
            if (desiredPickupEndTime) {
                if (desiredPickupEndTime > endTime) {
                    pickupEndTimeRef.current.set(endTime);
                    setDesiredPickupEndTime(endTime);
                }
            } else {
                pickupEndTimeRef.current.set(endTime);
                setDesiredPickupEndTime(endTime);
            }
        } else {
            setDesiredPickupEndTime(endTime);
        }
    }, [desiredPickupHours]);

    // This very average useEffect is needed to calculate the price for Thermo warm as it is calculated with a varying percentage of the DMT.
    useEffect(() => {
        if (
            additionalOptions &&
            additionalOptions.some((option) => option.name === thermoWarm)
        ) {
            let priceRequestBody = context.getApiOrderObject();
            priceRequestBody.service = context.quotation.service;
            priceRequestBody.pickupDate = desiredPickupDate;
            priceRequestBody.additionalOptions = additionalOptions.map(
                (option) => {
                    return {
                        name: option.name,
                        value: option.value,
                        parameters: option.parameters,
                    };
                },
            );

            if (addInsurance) {
                priceRequestBody.insurance = {
                    value: insuranceValue,
                    currency:
                        context.order.customs?.information?.currency ||
                        context.order.information?.currency ||
                        "SEK",
                };
            }

            const thermoWarmTrue = { name: thermoWarm, value: true };
            priceRequestBody.additionalOptions =
                priceRequestBody?.additionalOptions.map((option) =>
                    option.name === thermoWarm ? thermoWarmTrue : option,
                );
            if (!receiverPays) {
                Api.quoteOrder({
                    quote: priceRequestBody,
                })
                    .then((response) => {
                        if (response.data.code === 500) {
                            toast(
                                "Det gick inte att beräkna\n Termo Värme-priset",
                            );
                        } else {
                            const calculatedThermoPrice = (
                                response.data.price.total - price.total
                            ).toString();
                            setThermoPrice(calculatedThermoPrice);
                        }
                    })
                    .catch(() => {
                        toast("Det gick inte att beräkna\n Termo Värme-priset");
                    });
            }
        }
    }, [initialPriceLoaded]);

    useEffect(() => {
        if (!desiredPickupDate) {
            return;
        }
        if (insuranceValue > 3000000) {
            return;
        }
        setPrice(null);
        let priceRequestBody = context.getApiOrderObject();
        priceRequestBody.service = context.quotation.service;
        priceRequestBody.pickupDate = desiredPickupDate;

        if (additionalOptions && additionalOptions?.length) {
            priceRequestBody.additionalOptions = additionalOptions.map(
                (option) => {
                    if (
                        option.name === "notification" &&
                        isPrivateParcel &&
                        hasDeliveryWithPOD
                    ) {
                        return {
                            name: option.name,
                            value: true,
                        };
                    } else {
                        return {
                            name: option.name,
                            value: option.value,
                            parameters: option.parameters,
                        };
                    }
                },
            );
        }
        if (addInsurance) {
            priceRequestBody.insurance = {
                value: insuranceValue,
                currency:
                    context.order.customs?.information?.currency ||
                    context.order.information?.currency ||
                    "SEK",
            };
        }
        if (!additionalOptions) {
            Api.quoteAdditionalOptions(priceRequestBody)
                .then((response) => {
                    if (response.data.code !== 500) {
                        setAdditionalOptions(response.data);
                    }
                })
                .catch(() => {
                    toast("Det gick inte att hämta tilläggstjänster");
                });
            return;
        }

        setLoadingNewQuote(true);
        if (cancelSearchTokenRef.current) {
            cancelSearchTokenRef.current.cancel();
        }
        cancelSearchTokenRef.current = axios.CancelToken.source();
        if (!receiverPays) {
            Api.quoteOrder({
                cancelToken: cancelSearchTokenRef.current?.token,
                quote: priceRequestBody,
            })
                .then((response) => {
                    if (response.data.code === 500) {
                        setMessage({
                            title: "Meddelande från leverantören",
                            text: response.data.error,
                        });
                    } else {
                        const environment = context.quotation.environment;

                        const updatedQuote = {
                            ...response.data,
                            environment: environment,
                        };

                        context.setQuotation(updatedQuote);
                        if (desiredPickupDate !== response.data.pickupDate) {
                            setInvalidDate(true);
                        } else {
                            setSuggestedPickup(response.data.pickupDate);
                            setSuggestedDelivery(response.data.deliveryDate);
                            setPrice(response.data.price);
                            setInvalidDate(false);
                        }
                    }
                    setLoadingNewQuote(false);
                    setInitialPriceLoaded(true);
                })
                .catch((error) => {
                    if (axios.isCancel(error)) {
                        // Request was canceled
                    } else {
                        toast.error("Det gick inte att hämta pris");
                    }
                });
        }
    }, [
        desiredPickupDate,
        additionalOptions,
        insuranceValue,
        addInsurance,
        servicePointDeliveryOption,
        shipmentOptions,
    ]);

    useEffect(() => {
        if (
            [
                "DHLPaket",
                "DHLPaket2",
                "DHLPall",
                "DHLPallSpecial",
                "DHLParti",
                "DHLStycke",
                "DHLStyckeSpecial",
                "DHLHomeDelivery",
            ].indexOf(context.quotation.service.productCode) >= 0
        ) {
            const disabled =
                addInsurance || order.information?.dangerousGoods?.active;
            setShipmentOptions((options) => {
                return options.map((option) => {
                    if (option.parameter === "deliveryWithoutProofOfDelivery") {
                        return {
                            ...option,
                            disabled,
                        };
                    }
                    return option;
                });
            });
        } else {
            setShipmentOptions([]);
        }
    }, [addInsurance]);

    useEffect(() => {
        // servicePoint
        servicePoints.forEach((servicePoint) => {
            if (
                parseInt(servicePoint.index) ===
                parseInt(selectedServicePointIndex)
            ) {
                setSelectedServicePoint(servicePoint);
            }
        });
    }, [selectedServicePointIndex, servicePoints]);

    function getDesiredPickupDates() {
        return [0, 1, 2, 3, 4, 5, 6, 7]
            .map((i) => {
                let day = moment().add(i, "days");
                if ([6, 7].indexOf(day.isoWeekday()) < 0) {
                    return {
                        title: day.format("YYYY-MM-DD"),
                        value: day.format("YYYY-MM-DD"),
                    };
                }
                return null;
            })
            .filter((pickup) => {
                return !!pickup;
            });
    }

    function valid() {
        if (
            showCommercialInvoiceReminder &&
            !commercialInvoiceReminderAccepted
        ) {
            return false;
        }

        if (
            pickupInstructionsRef.current &&
            !pickupInstructionsRef.current.validate()
        ) {
            return false;
        }
        if (
            deliveryInstructionsRef.current &&
            !deliveryInstructionsRef.current.validate()
        ) {
            return false;
        }

        if (order.information?.dangerousGoods?.active) {
            if (!dangerousGoodsRef.current) {
                return false;
            }

            if (!dangerousGoodsRef.current.validate()) {
                return false;
            }

            if (!confirmDangerousGoods) {
                return false;
            }
        }

        if (addInsurance) {
            if (!insuranceTermsAccepted) {
                return false;
            }

            if (insuranceValue > 3000000) {
                return false;
            }
        }
        if (
            service.productCode === "DHLServicePointB2C" &&
            selectedServicePointIndex === null
        ) {
            return false;
        }
        return true;
    }

    function renderPickupDate(date) {
        if (!date) {
            return null;
        }
        switch (date) {
            case "ONE_WORK_DAY": {
                return (
                    <div className="suggestion">
                        <div className="timestamp">
                            Kan hämtas nästkommande arbetsdag.
                        </div>
                    </div>
                );
            }
            case "TWO_WORK_DAYS": {
                return (
                    <div className="suggestion">
                        <div className="timestamp">
                            Kan hämtas om två arbetsdagar.
                        </div>
                    </div>
                );
            }
            default: {
                return (
                    <div className="suggestion">
                        <div className="timestamp">
                            Kan hämtas{" "}
                            {moment(date).calendar(null, {
                                sameDay: "[idag]",
                                nextDay: "[imorgon]",
                                nextWeek: "[på] dddd",
                                sameElse: "LL",
                            })}
                            .
                        </div>
                    </div>
                );
            }
        }
    }

    function parseSuggestedPickup(date) {
        switch (date) {
            case "ONE_WORK_DAY": {
                let workDaysInFuture = 0;
                let dayPointer = moment();

                while (workDaysInFuture < 1) {
                    dayPointer.add(1, "day");
                    if (dayPointer.isoWeekday() < 6) {
                        workDaysInFuture++;
                    }
                }
                return dayPointer.format("YYYY-MM-DD");
            }
            case "TWO_WORK_DAYS": {
                let workDaysInFuture = 0;
                let dayPointer = moment();

                while (workDaysInFuture < 2) {
                    dayPointer.add(1, "day");
                    if (dayPointer.isoWeekday() < 6) {
                        workDaysInFuture++;
                    }
                }
                return dayPointer.format("YYYY-MM-DD");
            }
            case null: {
                return moment().format("YYYY-MM-DD");
            }
            default: {
                return date;
            }
        }
    }

    function getPriceNote() {
        return null;
    }

    function renderPickupDate(date) {
        if (!date) {
            return null;
        }
        return moment(date).calendar(null, {
            sameDay: "[idag]",
            nextDay: "[imorgon]",
            nextWeek: "[på] dddd",
            sameElse: "LL",
        });
    }

    function renderDeliveryDate(date) {
        if (!date) {
            return null;
        }
        switch (date) {
            case "INTERVAL_2_7": {
                return "2-7 arbetsdagar beroende på avstånd";
            }
            case "INTERVAL_2_5": {
                return "2-5 arbetsdagar beroende på avstånd";
            }
            default: {
                return moment(date).calendar(null, {
                    sameDay: "[idag]",
                    nextDay: "[imorgon]",
                    nextWeek: "[på] dddd",
                    sameElse: "LL",
                });
            }
        }
    }

    function orderHasPickupIncluded(productCode) {
        return (
            ["DHLEuroConnect", "DHLEuroline", "DHLEuroConnectPlus"].indexOf(
                productCode,
            ) >= 0
        );
    }

    function updateAddition({ name, value, parameters }) {
        setAdditionalOptions((additionalOptions) => {
            return additionalOptions.map((option) => {
                if (option.name === name) {
                    return {
                        ...option,
                        parameters: parameters || option.parameters,
                        value: value,
                    };
                } else {
                    return option;
                }
            });
        });
    }

    function getMessage() {
        if (invalidDate && !loadingNewQuote) {
            return "Upphämtningen kan inte ske denna dag. Försök med ett annat datum.";
        }
    }

    function showMaxInsuranceValueReminder(order) {
        if (order.customs?.value?.totalValue) {
            return true;
        }
        return false;
    }

    function showInsuranceValueReminder(order) {
        if (showMaxInsuranceValueReminder(order)) {
            return false;
        }
        if (
            !isMemberOfEU(order.sender.countryCode) ||
            !isMemberOfEU(order.receiver.countryCode)
        ) {
            return true;
        }
        return false;
    }

    function showExceptions() {
        setShowExceptionModal(true);
    }

    function hideExceptions() {
        setShowExceptionModal(false);
    }

    function getInsuranceExceptionText() {
        return "Tryck för att visa varor som inte kan försäkras";
    }
    function canUsePalletExchangeSystem() {
        if (!context.user.customer.settings.dhlPalletExchange) {
            return false;
        }
        if (
            ["DHLStycke", "DHLPall", "DHLParti"].indexOf(
                context.quotation.service.productCode,
            ) < 0
        ) {
            return false;
        }
        return true;
    }

    function showServicePointPicker() {
        if (
            service.productCode === "DHLServicePointB2C" ||
            service.productCode === "DHLParcelConnect"
        ) {
            // Documentation: https://www.dhldashboard.se/Portals/4/DHLToolbox/DHL%20FREIGHT%20(SWEDEN)%20-%20PRODUCT%20MANUAL%20v5.7.pdf
            if (
                [
                    "AT",
                    "BE",
                    "BG",
                    "CZ",
                    "DE",
                    "ES",
                    "FR",
                    "GB",
                    "HR",
                    "HU",
                    "IE",
                    "IT",
                    "LU",
                    "NL",
                    "PL",
                    "PT",
                    "RO",
                    "SI",
                    "SK",
                ].indexOf(context.order.receiver.countryCode) >= 0
            ) {
                return false;
            }
            return true;
        }
        return false;
    }

    function setShipmentOption({ parameter, value }) {
        setShipmentOptions((options) => {
            return options.map((option) => {
                if (option.parameter === parameter) {
                    return {
                        ...option,
                        value: value,
                    };
                }
                return option;
            });
        });
    }

    function openHomeDeliveryHelp() {
        setShowHomeDeliveryPopup(true);
    }

    const thermoWarmSelected = additionalOptions?.some(
        (option) => option.name === thermoWarm && option.value === true,
    );

    const thermoWarmIsOption = additionalOptions?.some(
        (option) => option.name === thermoWarm,
    );

    return (
        <>
            {/* If this service doesn't have any options we can just remove this section. */}
            {/* <Title>Alternativ</Title>
        <OrderOptions service={service} onChange={onOptionsChanged} /> */}
            <Title>Upphämtning</Title>
            <Card className="pickup">
                {!orderHasPickupIncluded(
                    context.quotation.service.productCode,
                ) && (
                    <div className="toggle">
                        <div className="label">Boka upphämtning</div>
                        <Input
                            type="checkbox"
                            value={includePickup}
                            onChange={setIncludePickup}
                        />
                    </div>
                )}
                {includePickup && desiredPickupDate && (
                    <>
                        <div className="datetime">
                            <label>Datum</label>
                            <label>Från</label>
                            <label>Till</label>
                            <Input
                                value={desiredPickupDate}
                                onChange={setDesiredPickupDate}
                                ref={pickupStartDateRef}
                                type="dropdown"
                                options={getDesiredPickupDates()}
                            />
                            {desiredPickupHours && !invalidDate && (
                                <Input
                                    type="dropdown"
                                    options={desiredPickupHours}
                                    ref={pickupStartTimeRef}
                                    onChange={setDesiredPickupStartTime}
                                    value={desiredPickupStartTime}
                                />
                            )}
                            {desiredPickupHours && !invalidDate && (
                                <Input
                                    type="dropdown"
                                    options={desiredPickupHours}
                                    ref={pickupEndTimeRef}
                                    onChange={setDesiredPickupEndTime}
                                    value={desiredPickupEndTime}
                                />
                            )}
                        </div>
                        <div className="subtitle">
                            Upphämtningsinstruktioner
                        </div>
                        <Input
                            className="input textarea"
                            ref={pickupInstructionsRef}
                            onChange={setPickupInstructions}
                            type="textarea"
                            maxLength={90}
                        />
                    </>
                )}
            </Card>
            <Title>Leverans</Title>
            <Card className="delivery">
                {showServicePointPicker() && (
                    <div
                        className={`option servicePoint ${
                            servicePointDeliveryOption === "SERVICE_POINT"
                                ? "is-selected"
                                : ""
                        }`}
                    >
                        <div className="text">
                            <div className="sub-title">Utlämningsställe</div>
                            <div className="description">
                                Paketet levereras till ett utlämningsställe där
                                mottagaren hämtar ut godset när den vill.
                            </div>

                            <Input
                                type="dropdown"
                                disabled={
                                    servicePointDeliveryOption !==
                                    "SERVICE_POINT"
                                }
                                onChange={setSelectedServicePointIndex}
                                options={servicePoints.map((servicePoint) => {
                                    return {
                                        value: servicePoint.index,
                                        title: servicePoint.title,
                                    };
                                })}
                            />
                        </div>
                    </div>
                )}
                {["DHLServicePointB2C"].indexOf(service.productCode) < 0 && (
                    <div className="instructions">
                        <div className="sub-title">Leveransinstruktioner</div>
                        <Input
                            className="input textarea"
                            ref={deliveryInstructionsRef}
                            onChange={setDeliveryInstructions}
                            type="textarea"
                            maxLength={140}
                        />
                    </div>
                )}
            </Card>
            <Title>Försäkring</Title>
            <Card className="insurance">
                <div className="toggle">
                    <Input
                        type="checkbox"
                        value={addInsurance}
                        onChange={setAddInsurance}
                    />
                    <div className="label">Jag vill försäkra min transport</div>
                </div>
                {addInsurance && (
                    <>
                        <div className="content">
                            <div className="label">Försäkringsvärde</div>
                            <InputNumber
                                ref={insuranceInputRef}
                                placeholder="0"
                                onChange={setInsuranceValue}
                                min={0}
                                max={3000000}
                                value={insuranceValue}
                                unit={
                                    context.order.customs?.information
                                        ?.currency ||
                                    context.order.information?.currency ||
                                    "SEK"
                                }
                                messages={{
                                    max: "Försäkringsvärdet får ej överstiga 3 000 000",
                                }}
                            />
                            {(!!price || loadingNewQuote) && !receiverPays && (
                                <div className="price">
                                    Med ett försäkringsvärde på{" "}
                                    {priceParser(insuranceValue || 0)}{" "}
                                    {context.order.customs?.information
                                        ?.currency ||
                                        context.order.information?.currency ||
                                        "SEK"}{" "}
                                    kostar försäkringen{" "}
                                    <b>
                                        {price?.insuranceCost !== undefined ? (
                                            price?.insuranceCost
                                        ) : (
                                            <Loader />
                                        )}{" "}
                                        SEK
                                    </b>
                                    .
                                </div>
                            )}
                        </div>
                        {showMaxInsuranceValueReminder(context.order) && (
                            <div className="reminder">
                                Ni har angett ett tullvärde på totalt{" "}
                                {priceParser(
                                    context.order.customs?.value?.totalValue,
                                )}{" "}
                                {context.order.customs?.information?.currency ||
                                    context.order.information?.currency ||
                                    "SEK"}{" "}
                                på tullfakturan vilket försäkringsvärdet ovan
                                inte för överstiga.
                            </div>
                        )}
                        {showInsuranceValueReminder(context.order) && (
                            <div className="reminder">
                                Observera att försäkringsvärdet inte får
                                överstiga det värde som skrivs på tullfakturan
                            </div>
                        )}
                        <div className="information">
                            <Input
                                type="checkbox"
                                value={insuranceTermsAccepted}
                                onChange={setInsuranceTermsAccepted}
                            />
                            <div className="label">
                                Jag förstår och accepterar{" "}
                                <a
                                    target="_blank"
                                    href="https://www.dhl.com/se-sv/home/vara-divisioner/frakt/ytterligare-tjanster.html"
                                >
                                    DHL:s villkor vid försäkring
                                </a>{" "}
                                samt att försäkringsvärdet inte kan överstiga
                                tull-värdet.
                            </div>
                            <div
                                data-tip
                                data-for={`tooltip`}
                                className="exceptions"
                                onClick={showExceptions}
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                >
                                    <path
                                        fill="currentColor"
                                        d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm1.25 17c0 .69-.559 1.25-1.25 1.25-.689 0-1.25-.56-1.25-1.25s.561-1.25 1.25-1.25c.691 0 1.25.56 1.25 1.25zm1.393-9.998c-.608-.616-1.515-.955-2.551-.955-2.18 0-3.59 1.55-3.59 3.95h2.011c0-1.486.829-2.013 1.538-2.013.634 0 1.307.421 1.364 1.226.062.847-.39 1.277-.962 1.821-1.412 1.343-1.438 1.993-1.432 3.468h2.005c-.013-.664.03-1.203.935-2.178.677-.73 1.519-1.638 1.536-3.022.011-.924-.284-1.719-.854-2.297z"
                                    />
                                </svg>
                                Varor som inte kan försäkras
                            </div>
                            <ReactTooltip
                                id={`tooltip`}
                                type="light"
                                effect="solid"
                                getContent={getInsuranceExceptionText}
                                arrowColor="transparent"
                                backgroundColor="#eee"
                            />
                        </div>
                    </>
                )}
            </Card>
            {canUsePalletExchangeSystem() && (
                <>
                    <Title>PÖS</Title>
                    <Card className="palletExchange">
                        {context.order.sender.dhlPalletExchangeNumber &&
                            context.order.receiver.dhlPalletExchangeNumber && (
                                <div className="text">
                                    Ange antalet EUR-pallar som skickas med
                                    denna försändelse.
                                    <div className="input">
                                        <InputNumber
                                            placeholder="0"
                                            onChange={setPalletExchangeCount}
                                            min={0}
                                            value={palletExchangeCount}
                                        />
                                    </div>
                                </div>
                            )}
                        {!(
                            context.order.sender.dhlPalletExchangeNumber &&
                            context.order.receiver.dhlPalletExchangeNumber
                        ) && (
                            <div className="text">
                                Både avsändare och mottagare måste ha ett
                                PÖS-nummer för att ni ska kunna använda PÖS.
                            </div>
                        )}
                    </Card>
                </>
            )}
            {additionalOptions !== null && additionalOptions?.length > 0 && (
                <>
                    <Title>Tillägg</Title>
                    {thermoWarmIsOption &&
                    !thermoPrice?.length &&
                    !receiverPays ? (
                        <Loader />
                    ) : (
                        <Card>
                            <div className="options">
                                {additionalOptions.map((option) => {
                                    if (
                                        !hasDeliveryWithPOD &&
                                        isPrivateParcel &&
                                        option.name === "notification"
                                    ) {
                                        return null;
                                    }
                                    return (
                                        <AdditionalOption
                                            key={option.name}
                                            option={option}
                                            thermoPrice={thermoPrice}
                                            isPrivateParcel={isPrivateParcel}
                                            hasDeliveryWithPOD={
                                                hasDeliveryWithPOD
                                            }
                                            receiverPays={receiverPays}
                                            updateAddition={updateAddition}
                                        />
                                    );
                                })}
                            </div>
                        </Card>
                    )}
                </>
            )}
            {shipmentOptions?.length > 0 && (
                <>
                    <Title>Tillval</Title>
                    <Card>
                        <div className="options">
                            {shipmentOptions.map((option) => {
                                return (
                                    <div className="option" key={option.name}>
                                        <div className="checkbox">
                                            <InputCheckbox
                                                value={option.value}
                                                disabled={
                                                    option.disabled ||
                                                    thermoWarmSelected
                                                }
                                                onChange={(value) =>
                                                    setShipmentOption({
                                                        parameter:
                                                            option.parameter,
                                                        value: value,
                                                    })
                                                }
                                            />
                                        </div>
                                        <div className="text">
                                            <div className="title">
                                                {option.title}
                                            </div>
                                            <div className="description">
                                                {option.text}
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </Card>
                </>
            )}
            <Title>Notiser</Title>
            <Card>
                <div className="description">
                    Välj vid vilka tillfällen och till vilka e-postadresser ni
                    vill att notiser ska skickas.
                </div>
                <div className="notifications">
                    {notificationSettings && (
                        <Input
                            type="table"
                            onChange={setNotificationSettings}
                            value={notificationSettings}
                            object={{
                                name: {
                                    type: "text",
                                    title: "Namn",
                                },
                                email: {
                                    type: "text",
                                    title: "Email",
                                },
                                onTender: {
                                    type: "checkbox",
                                    title: "Bokningsbekräftelse",
                                },
                            }}
                        />
                    )}
                </div>
            </Card>
            {context.order.information.dangerousGoods.active && (
                <DangerousGoods inputRef={dangerousGoodsRef} />
            )}
            <Title>Bekräfta & beställ</Title>
            <Card className="confirm">
                {order.information.dangerousGoods.active && (
                    <div className="dangerousGoods">
                        <div className="title">Friskrivningsklausul</div>
                        <div className="text">
                            <p>
                                Farligt gods/farligt material kräver särskilt
                                emballage och särskild märkning, etikettering
                                och dokumentation. Sådana försändelser måste
                                uppfylla myndighetskrav och DHL-bestämmelser.
                            </p>
                            <p>
                                Över 3 000 vanligt förekommande transporterade
                                material, t.ex. sprayburkar och brandfarlig
                                färg, betecknas som farliga. Stora böter och
                                fängelse finns i straffskalan.
                            </p>
                        </div>
                        <div className="confirm">
                            <Input
                                type="checkbox"
                                value={confirmDangerousGoods}
                                onChange={setConfirmDangerousGoods}
                            />
                            <div className="text">
                                Jag förstår och godkänner att jag själv är
                                ansvarig och står till svars om försändelsen på
                                något vis är felaktig.
                            </div>
                        </div>
                    </div>
                )}
                {showCommercialInvoiceReminder && (
                    <div className="commercialInvoiceReminder">
                        <Input
                            type="checkbox"
                            value={false}
                            onChange={setCommercialInvoiceReminderAccepted}
                        />
                        <div className="text">
                            Bekräfta: Då denna försändelse går utanför EUs
                            gränser måste jag skicka tull-fakturan och
                            trackingnummer till{" "}
                            <a
                                target="_blank"
                                href="mailto:dhlfreight.int.se@dhl.com"
                            >
                                dhlfreight.int.se@dhl.com
                            </a>
                        </div>
                    </div>
                )}
                <div className="summary">
                    Önskad upphämtning {renderPickupDate(desiredPickupDate)}{" "}
                    {suggestedDelivery && (
                        <>
                            {" "}
                            och estimerad leverans är{" "}
                            {renderDeliveryDate(suggestedDelivery)}.
                        </>
                    )}
                </div>
                <Confirmation
                    message={getMessage()}
                    price={price}
                    proceedWithoutPrice={context.quotation.proceedWithoutPrice}
                    valid={valid}
                    service={context.quotation.service}
                    disabled={!valid()}
                    receiverPays={receiverPays}
                    note={getPriceNote()}
                    bookOrder={() => {
                        let pickupData = {
                            location: pickupLocation,
                            date: suggestedPickup,
                        };

                        pickupData.date = parseSuggestedPickup(pickupData.date);

                        if (pickupData.date < desiredPickupDate) {
                            pickupData.date = desiredPickupDate;
                        }

                        let pickupStartTime, pickupEndTime;
                        if (includePickup) {
                            pickupStartTime =
                                pickupData.date +
                                " " +
                                desiredPickupStartTime +
                                ":00";
                            pickupEndTime =
                                pickupData.date +
                                " " +
                                desiredPickupEndTime +
                                ":00";
                        } else {
                            pickupStartTime = pickupData.date + " 08:00:00";
                            pickupEndTime = pickupData.date + " 18:00:00";
                        }

                        if (desiredPickupStartTime && desiredPickupEndTime) {
                            pickupData.instructions = `Wish for pickup to be between ${desiredPickupStartTime} and ${desiredPickupEndTime}.`;
                            if (pickupInstructions) {
                                pickupData.instructions += `\n ${pickupInstructions}`;
                            }
                        } else {
                            pickupData.instruction = pickupInstructions;
                        }
                        let deliveryData = {
                            instructions: deliveryInstructions || "",
                            servicePoint: selectedServicePoint,
                            date: suggestedDelivery,
                        };

                        let insurance = null;
                        if (addInsurance && insuranceValue) {
                            insurance = {
                                value: parseInt(insuranceValue),
                                currency:
                                    context.order.customs?.information
                                        ?.currency ||
                                    context.order.information?.currency ||
                                    "SEK",
                            };
                        }

                        let palletExchange = null;

                        if (
                            canUsePalletExchangeSystem() &&
                            context.order.sender.dhlPalletExchangeNumber &&
                            context.order.receiver.dhlPalletExchangeNumber
                        ) {
                            palletExchange = {
                                count: palletExchangeCount,
                                fromNumber:
                                    context.order.sender
                                        .dhlPalletExchangeNumber,
                                toNumber:
                                    context.order.receiver
                                        .dhlPalletExchangeNumber,
                            };
                        }

                        let bookOrderArguments = {
                            insurance: insurance,
                            palletExchange,
                            pickup: {
                                skipPickupOrder: !includePickup,
                                location: pickupData.location,
                                date: pickupData.date,
                                instructions: pickupData.instructions,
                                timeInterval: {
                                    start: pickupStartTime,
                                    end: pickupEndTime,
                                },
                                receiverPays: receiverPays || undefined,
                            },
                            delivery: deliveryData,
                        };

                        if (additionalOptions && additionalOptions?.length) {
                            bookOrderArguments.additionalOptions =
                                additionalOptions.map((option) => {
                                    return {
                                        name: option.name,
                                        value: option.value,
                                        parameters: option.parameters,
                                    };
                                });
                        }

                        if (receiverPays) {
                            bookOrderArguments.receiverPays = receiverPays;
                        }

                        if (
                            notificationSettings &&
                            additionalOptions?.length > 0
                        ) {
                            bookOrderArguments.additionalOptions =
                                [
                                    ...bookOrderArguments.additionalOptions,
                                    {
                                        name: "notificationSettings",
                                        value: notificationSettings,
                                    },
                                ] || [];
                        }

                        if (
                            notificationSettings &&
                            additionalOptions?.length === 0
                        ) {
                            bookOrderArguments.additionalOptions = [
                                {
                                    name: "notificationSettings",
                                    value: notificationSettings,
                                },
                            ];
                        }

                        if (shipmentOptions.length) {
                            bookOrderArguments.shipmentOptions = shipmentOptions
                                .map((option) => {
                                    if (!option.disabled) {
                                        return {
                                            parameter: option.parameter,
                                            value: option.value,
                                        };
                                    }
                                })
                                .filter((v) => v);
                        }

                        bookOrder(bookOrderArguments);
                    }}
                />
                {message && (
                    <div className="message">
                        <div className="text">{message.title}</div>
                        <div className="details">{message.text}</div>
                    </div>
                )}
            </Card>
            {showExceptionModal && (
                <ExceptionsModal onClick={hideExceptions}>
                    <div
                        className="container"
                        onClick={(ev) => ev.stopPropagation()}
                    >
                        <div className="title">
                            Varor som inte täcks av DHL:s försäkring
                        </div>
                        <div className="subtitle">
                            Läs mer på{" "}
                            <a
                                target="_blank"
                                href="https://www.dhl.com/se-sv/home/vara-divisioner/frakt/ytterligare-tjanster/forsakringstackning-undantag.html"
                            >
                                DHL:s hemsida
                            </a>
                        </div>
                        <ul>
                            <li>Ammunition, vapen, sprängämnen</li>
                            <li>Antikviteter, konst och bordssilver</li>
                            <li>
                                Andra betalningsmedel än kontanter (t.ex.
                                checkar, kreditkort, skattemärken, värdepapper,
                                överlåtbara värdepapper, statsobligationer och
                                liknande)
                            </li>
                            <li>
                                Last som transporteras av egen kraft eller genom
                                att bogseras (t.ex. motorfordon, motorcyklar,
                                husvagnar, hästtransporter, båtar)
                            </li>
                            <li>
                                Saker som ägs av, hyrs av, leasas eller lånas ut
                                till DHL Freight
                            </li>
                            <li>Blod och/eller blodplasma</li>
                            <li>
                                Bulklaster med torrvaror och flytande varor,
                                inklusive varor i flexitankar
                            </li>
                            <li>Guldtackor och ädelmetaller </li>
                            <li>Cement och cementprodukter</li>
                            <li>
                                Cigaretter, cigarrer och andra färdiga
                                tobaksprodukter
                            </li>
                            <li>
                                Farligt gods i klass 1, 6.2, 7, 9,
                                UN3111–UN3120, UN3231–UN3240, UN3507
                            </li>
                            <li>
                                Prototyper, mönster, ritningar, manuskript och
                                andra liknande originalhandlingar
                            </li>
                            <li>Päls och pälskläder</li>
                            <li>Husgeråd och personlig egendom</li>
                            <li>Smycken</li>
                            <li>
                                Boskap, fullblodshästar, levande varelser och
                                levande växter
                            </li>
                            <li>Lösa ädelstenar</li>
                            <li>
                                Pengar i alla olika former (t.ex. kontanter,
                                sedlar, mynt)
                            </li>
                            <li>
                                Förbrukningsvaror och temperaturkänsliga varor
                            </li>
                            <li>Prototyper och unika produkter</li>
                            <li>Frimärken, biljetter och liknande</li>
                            <li>Rena handelsmetaller som koppar, tenn, osv.</li>
                            <li>
                                Opackat och oskyddad stål (t.ex. rör, rullar,
                                balkar, ark, osv.)
                            </li>
                        </ul>
                    </div>
                </ExceptionsModal>
            )}
        </>
    );
}

export default Component;
