import { FortnoxTriggerStatuses, ReferenceOptions } from "../Create/Fortnox";

import { countryList } from "../../../components/Company/ShipmentContactForm/countryList";
import SupportedCarriers from "../Components/SupportedCarriers";
import { fetchVismaExtensionInstaller } from "../Create/VismaAdministration/Helpers/VismaHelper";

/**
 * ConfigurationOptions defines the configuration settings for various integrations.
 *
 * When adding a new integration, follow the structure for existing integrations.
 * Each integration can have the following properties:
 *
 * - `updateConfigurationSettings`: A boolean that determines if the configuration settings for this integration
 *   are editable by the user. If set to `false`, no settings will be displayed for this integration.
 *
 * Each integration can also define different configuration fields, such as:
 *
 * - `key`: A unique identifier for the configuration field.
 * - `title`: The human-readable title for the field, used as a label in the UI.
 * - `type`: The type of the configuration field, which can be one of the following:
 *      - `"text"`: Displays a non-editable text field (useful for API keys).
 *      - `"input"`: Displays an editable text input field (e.g., for entering URLs or domains).
 *      - `"switch"`: Displays a toggle switch (boolean values).
 *      - `"dropdown"`: Displays a dropdown menu with a list of options.
 *      - `"link"`: Displays a hyperlink to an external resource.
 *      - `"download"`: Displays a link to trigger a file download.
 *
 * Example Configuration for a New Integration:
 *
 * ```js
 * NEW_INTEGRATION: {
 *     updateConfigurationSettings: true, // allows configuration changes
 *     apiKey: {
 *         key: "apiKey",
 *         title: "API Nyckel",
 *         type: "text", // non-editable text field
 *     },
 *     shopDomain: {
 *         key: "shopDomain",
 *         title: "Webshop Domän",
 *         type: "input", // editable input field
 *     },
 *     includeOrders: {
 *         key: "includeOrders",
 *         title: "Inkludera fakturerade ordrar",
 *         type: "switch", // toggle switch
 *     },
 *     orderSource: {
 *         key: "orderSource",
 *         title: "Välj Order Källa",
 *         type: "dropdown", // dropdown field
 *         options: [
 *             { title: "Fraktsedelsrader", value: "ShippingLabelLines" },
 *             { title: "Orderrader", value: "OrderLines" },
 *         ],
 *     },
 *     documentation: {
 *         key: "documentation",
 *         title: "API Dokumentation",
 *         type: "link", // hyperlink field
 *         link: {
 *             title: "Läs API dokumentation",
 *             location: "https://example.com/api-docs",
 *         },
 *     },
 * },
 * ```
 *
 * Note:
 * - Make sure the `key` is unique within the integration.
 * - The `type` must correspond to the correct field type to ensure proper rendering in the UI.
 * - For `dropdown` fields, an array of `options` is required, each with a `title` and `value`.
 */
export const ConfigurationOptions = {
    ZENDR: {
        updateConfigurationSettings: true,
        apiKey: {
            key: "identifier",
            title: "API Nyckel",
            type: "text",
        },
        system: {
            key: "system",
            title: "System",
            type: "text",
            options: [
                { title: "Visma Business NXT", value: "Visma Business NXT" },
                { title: "Sage X3", value: "Sage X3" },
                { title: "Jeeves", value: "Jeeves" },
                { title: "Annat System", value: "Annat System" },
            ],
        },
        otherSystem: {
            key: "otherSystem",
            title: "Annat System",
            type: "text",
        },
        webhookUrl: {
            key: "webhookUrl",
            title: "Webhook URL",
            type: "input",
        },
        download: {
            key: "download",
            title: "OpenAPI Specifikation",
            type: "download",
            downloadPath: {
                objectKey: `zendr/zendr-api_${process.env.REACT_APP_ZENDR_API_LATEST_VERSION}.yaml`,
                filename: `zendr-api_${process.env.REACT_APP_ZENDR_API_LATEST_VERSION}.yaml`,
            },
        },
    },
    MONITOR_G5: {
        updateConfigurationSettings: true,
        username: {
            key: "username",
            title: "Användarnamn",
            type: "input",
        },
        password: {
            key: "password",
            title: "Lösenord",
            type: "input",
        },
        hostname: {
            key: "hostname",
            title: "Hostname",
            type: "input",
        },
        port: {
            key: "port",
            title: "Port",
            type: "input",
        },
        languageCode: {
            key: "languageCode",
            title: "Språkkod",
            type: "dropdown",
            options: countryList,
        },
        companyNumber: {
            key: "companyNumber",
            title: "Företagsnummer",
            type: "input",
        },
        transferTriggerStatus: {
            key: "transferTriggerStatus",
            title: "Transfer Trigger Status",
            type: "dropdown",
            options: [
                { title: "1. Registrerad", value: "1" },
                { title: "2. Utskriven", value: "2" },
                { title: "3. Leveranstid kan ej bekräftas", value: "3" },
                { title: "4. Pågående plockning", value: "4" },
                { title: "5. Dellevererad", value: "5" },
                { title: "9. Slutlevererad", value: "9" },
            ],
        },
        reference: {
            key: "reference",
            title: "Referens",
            type: "checkbox",
            options: [
                { value: "orderNumber", label: "Ordernummer" },
                { value: "customerOrderNumber", label: "Kundens Ordernummer" },
                { value: "goodsReferenceName", label: "Godsmott. ref." },
            ],
        },
        goodsDescription: {
            key: "goodsDescription",
            title: "Godsbeskrivning",
            type: "checkbox",
            options: [
                { value: "orderType", label: "Ordertyp" },
                { value: "goodsLabel", label: "Godsmärke" },
                { value: "customText", label: "Egen Godsbeskrivning" },
            ],
        },
        customGoodsDescription: {
            key: "customGoodsDescription",
            title: "Egen Godsbeskrivning",
            type: "input",
        },
    },
    FORTNOX: {
        updateConfigurationSettings: true,
        deliveryOptions: {
            key: "deliveryOptions",
            title: "Leveransalternativ",
            type: "dropdown",
            options: [
                { title: "Leveransuppgifter", value: "DeliveryDetails" },
                { title: "Kunduppgifter", value: "CustomerDetails" },
            ],
        },
        packageDescription: {
            key: "packageDescription",
            title: "Godsbeskrivning",
            type: "dropdown",
            options: [
                { title: "Artikelnummer", value: "ArticleNumber" },
                { title: "Benämning", value: "Name" },
            ],
        },
        reference: {
            key: "reference",
            title: "Referens",
            type: "checkbox",
            options: ReferenceOptions,
        },
        transferTriggerStatus: {
            key: "transferTriggerStatus",
            title: "Status för överföring",
            type: "dropdown",
            options: FortnoxTriggerStatuses,
        },
        requireOrderValue: {
            key: "requireOrderValue",
            title: "Importera endast ordrar med ett värde större än 0",
            type: "switch",
        },
        includeInvoicedOrders: {
            key: "includeInvoicedOrders",
            title: "Inkludera fakturerade ordrar",
            type: "switch",
        },
    },
    LOG_TRADE: {
        updateConfigurationSettings: false,
        identifier: {
            key: "identifier",
            title: "API Nyckel",
            type: "text",
        },
    },
    SHOPIFY: {
        updateConfigurationSettings: true,
        identifier: {
            key: "identifier",
            title: "Webshop domän",
            type: "input",
        },
    },
    WOO_COMMERCE: {
        updateConfigurationSettings: true,
        identifier: {
            key: "identifier",
            title: "Webshop domän",
            type: "input",
        },
        importFilteringEnabled: {
            key: "importFilteringEnabled",
            title: "Filtrera ordrar vid import",
            type: "dropdown",
            options: [
                { title: "Ja", value: true },
                { title: "Nej", value: false },
            ],
        },
        shippingRateFiltering: {
            key: "shippingRateFiltering",
            title: "Föreslå transportör vid prisförfrågan",
            type: "dropdown",
            options: [
                { title: "Ja", value: true },
                { title: "Nej", value: false },
            ],
        },
        additonalInformation: {
            key: "additonalInformation",
            title: "Tilläggsinformation",
            renderComponent: () => (
                <SupportedCarriers extension="WooCommerce" />
            ),
        },
    },
    MAGENTO: {
        updateConfigurationSettings: true,
        identifier: {
            key: "identifier",
            title: "Webshop domän",
            type: "input",
        },
        download: {
            key: "download",
            title: "Magento modul",
            type: "download",
            downloadPath: {
                objectKey: `magento/zendr-magento_${process.env.REACT_APP_MAGENTO_LATEST_VERSION}.zip`,
                filename: `zendr-magento_${process.env.REACT_APP_MAGENTO_LATEST_VERSION}.zip`,
            },
        },
    },
    QUICK_BUTIK: {
        updateConfigurationSettings: true,
        identifier: {
            key: "identifier",
            title: "Webhook hemlighet",
            type: "input",
        },
        apiKey: {
            key: "apiKey",
            title: "API Nyckel",
            type: "input",
        },
    },
    STARWEB: {
        updateConfigurationSettings: true,
        apiKey: {
            key: "clientId",
            title: "Client ID",
            type: "input",
        },
        clientSecret: {
            key: "clientSecret",
            title: "Client Secret",
            type: "input",
        },
        identifier: {
            key: "shopIdentifier",
            title: "Webshop domän",
            type: "input",
        },
    },
    JOOMLA_VIRTUEMART: {
        updateConfigurationSettings: true,
        apiKey: {
            key: "accessToken",
            title: "API Nyckel",
            type: "text",
        },
        username: {
            key: "username",
            title: "Användarnamn",
            type: "input",
        },
        password: {
            key: "password",
            title: "Lösenord",
            type: "input",
        },
        identifier: {
            key: "identifier",
            title: "Webshop domän",
            type: "input",
        },
        download: {
            key: "download",
            title: "VirtueMart plugin",
            type: "download",
            downloadPath: {
                objectKey: `joomla/zendr-joomla_${process.env.REACT_APP_JOOMLA_LATEST_VERSION}.zip`,
                filename: `zendr-joomla_${process.env.REACT_APP_JOOMLA_LATEST_VERSION}.zip`,
            },
        },
    },
    BUSINESS_CENTRAL: {
        updateConfigurationSettings: true,
        companyName: {
            key: "companyName",
            title: "Företagsnamn",
            type: "text",
        },
        clientId: {
            key: "clientId",
            title: "Client ID",
            type: "text",
        },
        environment: {
            key: "environment",
            title: "Miljö",
            type: "input",
        },
        fetchStatus: {
            key: "fetchStatus",
            title: "Hämta status",
            type: "dropdown",
            options: [
                { title: "Öppen", value: "Draft" },
                { title: "Släppt", value: "Open" },
            ],
        },
        deliveryCodes: {
            key: "deliveryCodes",
            title: "Leveranskoder",
            type: "list",
        },
    },
    PYRAMID: {
        updateConfigurationSettings: false,
        identifier: {
            key: "identifier",
            title: "API Nyckel",
            type: "text",
        },
        download: {
            key: "download",
            title: "Pyramid modul",
            type: "download",
            downloadPath: {
                objectKey: `pyramid/dsexp_zendr_${process.env.REACT_APP_PYRAMID_LATEST_VERSION}.zip`,
                // This is a required pattern for the filename in pyramid integration
                filename: `dsexp_zendr_${process.env.REACT_APP_PYRAMID_LATEST_VERSION}.zip`,
            },
        },
    },
    ABICART: {
        updateConfigurationSettings: true,
        identifier: {
            key: "identifier",
            title: "Webshop ID",
            type: "input",
        },
        authToken: {
            key: "authToken",
            title: "Auth Token",
            type: "input",
        },
    },
    ONGOING_WMS: {
        updateConfigurationSettings: true,
        accessToken: {
            key: "accessToken",
            title: "API Nyckel",
            type: "text",
        },
        identifier: {
            key: "identifier",
            title: "Warehouse",
            type: "input",
        },
        username: {
            key: "username",
            title: "API Användarnamn",
            type: "input",
        },
        password: {
            key: "password",
            title: "API Lösenord",
            type: "input",
        },
        selectedApi: {
            key: "selectedApi",
            title: "Valt API",
            type: "dropdown",
            options: [
                { title: "Automation", value: "automation" },
                { title: "Service", value: "godsagare" },
            ],
        },
        pickupAddress: {
            key: "pickupAddress",
            title: "Upphämtning Adress",
            type: "dropdown",
            options: [
                { title: "Godsägare", value: "Godsägare" },
                { title: "Kontohavare", value: "Kontohavare" },
            ],
        },
        includeOrderIdInReference: {
            key: "includeOrderIdInReference",
            title: "Inkludera order id i referens",
            type: "dropdown",
            options: [
                { title: "Ja", value: "Ja" },
                { title: "Nej", value: "Nej" },
            ],
        },
        selectGoodsSource: {
            key: "selectGoodsSource",
            title: "Välj Gods Källa",
            type: "dropdown",
            options: [
                { title: "Fraktsedelsrader", value: "Fraktsedelsrader" },
                { title: "Orderrader", value: "Orderrader" },
            ],
        },
    },
    VISMA_ADMINISTRATION: {
        updateConfigurationSettings: false,
        apiKey: {
            key: "identifier",
            title: "API Nyckel",
            type: "text",
        },
        command: {
            key: "command",
            title: "Visma Extension Installationsfil",
            type: "command",
            commandTitle: "Zendr_Visma_Extension_Installer.exe",
            get action() {
                return () => fetchVismaExtensionInstaller();
            },
        },
    },
};
