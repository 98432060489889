import DisplayField from "../../../Components/DisplayField";

function AbicartStepTwo() {
    return (
        <div>
            <h3 className="text-2xl font-semibold mb-8">
                Steg 2 -{" "}
                <span className="text-lg font-medium text-accent">
                    Konfigurera Eventlyssnare
                </span>
            </h3>

            <div className="flex items-start">
                <div className="flex flex-col space-y-8 w-1/2">
                    <ul className="text-md font-medium list-disc list-outside pl-6 space-y-4 mb-20">
                        <li>Klicka på "Lägg till" under "Event Listeners".</li>
                        <li>
                            Välj ett event som ska trigga eventlyssnaren, t.ex.
                            när orderstatus ändras till "Order/paid".
                        </li>
                        <div className="flex flex-col">
                            <li>
                                Kopiera och klistra in följande URL i fältet
                                "URL":
                            </li>
                            <DisplayField
                                value={`${process.env.REACT_APP_API_URL}/api/wh/PNMruR22KhODnKhnGmRgbR1TnRBZdhChGqqKpYfmDLqnarTjJ3e3kQiQz6UchqY3`}
                                copyToClipboard={true}
                            />
                        </div>
                        <li>Välj den Auth Token du skapade i steg 1.</li>
                        <li>Klicka på "Skapa Event Listener" för att spara.</li>
                        <li>Fortsätt till nästa steg.</li>
                    </ul>
                </div>

                <div className="ml-10 mr-10 space-y-6">
                    <img
                        src={`/images/extensions/abicart/eventlistener.png`}
                        alt="Event Listener"
                        className="object-contain w-[32rem] h-[32rem] max-w-2xl"
                    />
                </div>
            </div>
        </div>
    );
}

export default AbicartStepTwo;
