// This name should be updated to match receiver-pays supported carriers
export const CARRIER_CONSTS = {
    carriers: [
        { value: "DHL Freight", label: "DHL Freight" },
        { value: "DHL Express", label: "DHL Express" },
        //{ value: "DSV", label: "DSV" },
        { value: "FedEx", label: "FedEx" },
        { value: "PostNord", label: "PostNord" },
        { value: "TNT", label: "TNT" },
        { value: "UPS", label: "UPS" },
    ],
};

export const SUPPORTED_CARRIERS = {
    carriers: [
        { value: "DHL" },
        { value: "DHL Express" },
        { value: "DSV" },
        { value: "FedEx" },
        { value: "PostNord" },
        { value: "TNT" },
        { value: "UPS" },
        { value: "Bring" },
    ],
};
