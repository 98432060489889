import { handleDownloadS3 } from "../../../utils/s3";

/**
 * Renders a simple text field or masked password field.
 * @param {string} value - The value to display.
 * @param {boolean} isPassword - Whether the field is a password (masked) field.
 * @returns {JSX.Element} - The rendered text or password field.
 */
const renderTextField = (value, isPassword) => {
    if (!value) {
        return null;
    }

    return <p className="p-1 h-8">{isPassword ? "*********" : value}</p>;
};
/**
 * Renders an input field, editable when in edit mode.
 * @param {string} value - The current value of the input field.
 * @param {function} handleChange - The function to handle value changes.
 * @param {boolean} isPassword - Whether the input is for a password (masked).
 * @returns {JSX.Element} - The rendered input field.
 */
const renderInputField = (value, handleChange, isPassword) => (
    <input
        type={isPassword ? "password" : "text"}
        value={value}
        onChange={handleChange}
        className="w-full p-1 h-8 border border-gray-300 rounded-md"
    />
);

/**
 * Renders a list field.
 * @param {Array} value - The current list of items to render.
 * @returns {JSX.Element} - The rendered list field.
 */
const renderListField = (value) => (
    <div className="flex flex-col space-y-2">
        {value.map((item, index) => (
            <div key={index} className="flex items-center space-x-2">
                <p className="w-full p-1 h-8">{item}</p>
            </div>
        ))}
    </div>
);

/**
 * Renders a dropdown field with options.
 * @param {string} value - The selected value in the dropdown.
 * @param {function} handleChange - The function to handle value changes.
 * @param {Array} options - The list of options for the dropdown.
 * @param {boolean} editMode - Whether the dropdown is editable.
 * @returns {JSX.Element} - The rendered dropdown or selected value.
 */
const renderDropdownField = (value, handleChange, options, editMode) =>
    editMode ? (
        <select
            value={value}
            onChange={handleChange}
            className="w-full h-8 border border-gray-300 rounded-md"
        >
            {options.map((option) => (
                <option key={option.value} value={option.value}>
                    {option.title}
                </option>
            ))}
        </select>
    ) : (
        <p className="w-full p-1 h-8">
            {options.find((option) => option.value === value)?.title ||
                "Inställning saknas"}
        </p>
    );

/**
 * Renders a switch (checkbox) field.
 * @param {boolean} value - The current value of the switch.
 * @param {function} handleChange - The function to handle toggle changes.
 * @param {boolean} editMode - Whether the switch is editable.
 * @returns {JSX.Element} - The rendered switch or selected value (Ja/Nej).
 */
const renderSwitchField = (value, handleChange, editMode) =>
    editMode ? (
        <div className="flex items-center mt-1">
            <label className="switch">
                <input
                    type="checkbox"
                    checked={value === true}
                    onChange={handleChange}
                />
                <span className="slider"></span>
            </label>
        </div>
    ) : (
        <p className="p-1 h-8">{value === true ? "Ja" : "Nej"}</p>
    );

/**
 * Renders a link.
 * @param {Object} link - The link object containing the title and location.
 * @returns {JSX.Element} - The rendered link element.
 */
const renderLinkField = (link) => (
    <p className="w-full p-1 h-8">
        <a
            href={link.location}
            className="text-blue-500 underline"
            target="_blank"
            rel="noopener noreferrer"
        >
            {link.title}
        </a>
    </p>
);

/**
 * Renders a download field that triggers a file download from S3.
 * @param {Object} fieldConfig - The configuration for the download field.
 * @returns {JSX.Element} - The rendered download link.
 */
const renderDownloadField = (fieldConfig) => (
    <p className="w-full p-1 h-8">
        <a
            className="text-blue-700 cursor-pointer underline"
            onClick={() =>
                handleDownloadS3({
                    url: `${
                        process.env.REACT_APP_API_URL
                    }/api/s3/integration?objectKey=${encodeURIComponent(
                        fieldConfig.downloadPath.objectKey,
                    )}`,
                    desiredFileName: fieldConfig.downloadPath.filename,
                })
            }
        >
            {fieldConfig.downloadPath.filename}
        </a>
    </p>
);

/**
 * Renders a command field that triggers a specified action.
 * @param {Object} fieldConfig - The configuration for the command field.
 * @returns {JSX.Element} - The rendered command field.
 */
const renderCommandField = (fieldConfig) => (
    <p className="w-full p-1 h-8">
        <a
            className="text-blue-700 cursor-pointer underline"
            onClick={() => fieldConfig.action()}
        >
            {fieldConfig.commandTitle}
        </a>
    </p>
);

/**
 * Renders a group of checkboxes.
 * @param options
 * @param selectedValues
 * @param handleChange
 * @param editMode
 * @returns {JSX.Element}
 */
const renderCheckboxGroup = (
    options,
    selectedValues = [],
    handleChange,
    editMode,
) => {
    // Normalize selectedValues to always be an array (handles string values from settings migration).
    const normalizedSelectedValues = [selectedValues].flat();

    return (
        <div className="flex flex-col gap-2">
            {options.map((option) => (
                <label
                    key={option.value}
                    className="flex items-center space-x-2 font-medium ml-2"
                >
                    <input
                        type="checkbox"
                        checked={normalizedSelectedValues.includes(
                            option.value,
                        )}
                        onChange={() => handleChange(option.value)}
                        className="form-checkbox w-5 h-5"
                        disabled={!editMode}
                        style={{ accentColor: "rgba(76, 80, 175, 1)" }}
                    />
                    <span>{option.label}</span>
                </label>
            ))}
        </div>
    );
};

const ConfigurableForm = ({
    config,
    currentState,
    editMode,
    handleInputChange,
}) => {
    const handleCheckboxToggle = (fieldKey, selectedOption) => {
        let selectedValues = currentState[fieldKey] || [];

        // Normalize selectedValues to always be an array (handles string values from settings migration).
        const normalizedSelectedValues = [selectedValues].flat();

        const updatedValues = normalizedSelectedValues.includes(selectedOption)
            ? normalizedSelectedValues.filter(
                  (value) => value !== selectedOption,
              )
            : [...normalizedSelectedValues, selectedOption];

        handleInputChange(fieldKey, updatedValues);
    };

    const renderField = (fieldConfig, value) => {
        switch (fieldConfig.type) {
            case "text":
                return renderTextField(value, fieldConfig.key === "password");
            case "input":
                return editMode
                    ? renderInputField(
                          value,
                          (e) =>
                              handleInputChange(
                                  fieldConfig.key,
                                  e.target.value,
                              ),
                          fieldConfig.key === "password",
                      )
                    : renderTextField(value, fieldConfig.key === "password");
            case "list":
                return renderListField(
                    value,
                    (e) => handleInputChange(fieldConfig.key, e.target.value),
                    editMode,
                );
            case "dropdown":
                return renderDropdownField(
                    value,
                    (e) => handleInputChange(fieldConfig.key, e.target.value),
                    fieldConfig.options,
                    editMode,
                );
            case "checkbox":
                return renderCheckboxGroup(
                    fieldConfig.options,
                    value,
                    (selectedOption) =>
                        handleCheckboxToggle(fieldConfig.key, selectedOption),
                    editMode,
                );
            case "switch":
                return renderSwitchField(
                    value,
                    (e) => handleInputChange(fieldConfig.key, e.target.checked),
                    editMode,
                );
            case "link":
                return renderLinkField(fieldConfig.link);
            case "download":
                return renderDownloadField(fieldConfig);
            case "command":
                return renderCommandField(fieldConfig);
            default:
                return renderTextField(value, fieldConfig.key === "password");
        }
    };

    return (
        <div className="space-y-6 w-3/4">
            {Object.keys(config).map((fieldKey) => {
                const fieldConfig = config[fieldKey];
                const value = currentState[fieldConfig.key] ?? "";

                return (
                    <div key={fieldKey}>
                        <label className="font-semibold text-gray-700">
                            {fieldConfig.title}
                        </label>
                        {renderField(fieldConfig, value)}
                        {fieldConfig.renderComponent &&
                            fieldConfig.renderComponent()}
                    </div>
                );
            })}
        </div>
    );
};

export default ConfigurableForm;
