import Input from "components/OldInput";
import React, { useContext } from "react";
import TimeDefiniteComponent from "./AdditionalOptions/TimeDefiniteComponent";
import Context from "context/Global";
import { getTimeDefiniteOptions } from "utils/timeDefiniteOptions";
import { isStockholmPostalCode } from "utils/isStockholmPostalCode";

const AdditionalOption = ({
    thermoPrice,
    option,
    updateAddition,
    hasDeliveryWithPOD,
    isPrivateParcel,
    receiverPays,
}) => {
    const context = useContext(Context);
    const quotation = context.quotation;
    const order = context.order;
    const pickupDate = quotation.pickupDate;
    const deliveryDate = quotation.deliveryDate;
    function getOptionTitle(name) {
        switch (name) {
            case "tailLiftLoading": {
                return "Bakgavellift vid lastning";
            }
            case "tailLiftUnloading": {
                return "Bakgavellift vid lossning";
            }
            case "preAdviceByDriver": {
                return "Leveransbesked";
            }
            case "dangerousGoods": {
                return "Farligt Gods";
            }
            case "dangerousGoodsLimitedQuantity": {
                return "Farligt gods i begränsad mängd";
            }
            case "notification": {
                return isPrivateParcel && hasDeliveryWithPOD
                    ? "Avisering (obligatoriskt för privatpersoner om inte leverans utan kvittens är valt)"
                    : "Avisering";
            }
            case "greenFreight": {
                return "Skicka grönt";
            }
            case "thermoWarm": {
                return "Termo Värme";
            }
            default: {
                return "";
            }
        }
    }
    function getOptionSubTitle(name) {
        switch (name) {
            case "tailLiftLoading": {
                return "När du väljer det här alternativet tar vi kontakt med DHL och får ett pris som vi sen informerar er om via vår kundservice. Minsta kostnad är på 1 000 kr och stora försändelser kan kosta över 3-4 000 kronor. Vill du veta på förhand får du gärna ringa kundservice så ger vi er ett estimat alternativt frågar DHL om exakt pris innan ni bokar.";
            }
            case "tailLiftUnloading": {
                return "När du väljer det här alternativet tar vi kontakt med DHL och får ett pris som vi sen informerar er om via vår kundservice. Minsta kostnad är på 1 000 kr och stora försändelser kan kosta över 3-4 000 kronor. Vill du veta på förhand får du gärna ringa kundservice så ger vi er ett estimat alternativt frågar DHL om exakt pris innan ni bokar.";
            }
            case "preAdviceByDriver": {
                return "Med leveransbesked ringer föraren mottagaren cirka 15-30 minuter innan leverans.";
            }
            case "dangerousGoods": {
                return "Beksrivning av tjänsten.";
            }
            case "dangerousGoodsLimitedQuantity": {
                return "Beksrivning av tjänsten.";
            }
            case "notification": {
                return "När sändningen ankommit till sista terminal kontaktas mottagaren och de kommer överens om ett tidsintervall när leveransen skall ske.";
            }
            case "greenFreight": {
                return "Varje gång du som kund väljer SKICKA GRÖNT producerar DHL motsvarande tonkilometer med klimatsmarta teknik- och bränslelösningar någonstans i deras svenska nätverk.";
            }
            case "thermoWarm": {
                return "Med Termo Värme avses att godset transporteras frostfritt (lägst +1 grad). Kan ej bokas med tillvalen Leverans utan kvittens, Prioriterad leverans (PRE 7, PRE 10 och PRE 12).";
            }
            case "timeDefiniteUnloading": {
                return "Tidsbestämd lossning innebär att sändning lossas på avtalad tid eller inom avtalat tidsintervall på leveransdagen.";
            }
            case "timeDefiniteLoading": {
                return "Tidsbestämd lastning innebär att sändning lastas på avtalad tid eller inom avtalat tidsintervall på upphämtningsdagen.";
            }
            default: {
                return "";
            }
        }
    }

    function renderServiceSpecificOptions(option) {
        if (!option.value) {
            return;
        }

        switch (option.name) {
            case "timeDefiniteUnloading":
            case "timeDefiniteLoading": {
                return (
                    <TimeDefiniteComponent
                        option={option}
                        updateAddition={updateAddition}
                    />
                );
            }
        }
    }

    function getDefaultParameters(option) {
        switch (option.name) {
            case "timeDefiniteUnloading":
            case "timeDefiniteLoading": {
                const defaultTimeDefiniteOption = getTimeDefiniteOptions(
                    pickupDate,
                    deliveryDate,
                    order,
                    option,
                )?.[0];

                return {
                    datetime: defaultTimeDefiniteOption?.value,
                };
            }
            default: {
                return {};
            }
        }
    }

    function getPrice(option) {
        if (receiverPays) {
            return null;
        }

        switch (option.name) {
            case "timeDefiniteLoading":
            case "timeDefiniteUnloading": {
                const party =
                    option.name === "timeDefiniteLoading"
                        ? order.sender
                        : order.receiver;

                const useStockholmPriceList = isStockholmPostalCode(
                    party?.postalCode,
                    party?.countryCode,
                );

                if (useStockholmPriceList) {
                    return "fr. 1300 kr";
                }

                return "fr. 1080 kr";
            }

            default: {
                if (option.cost) {
                    return `+ ${
                        option.name === "thermoWarm" ? thermoPrice : option.cost
                    } kr`;
                }
                return "Enligt ö.k.";
            }
        }
    }

    return (
        <div className="option" key={option.name}>
            <div className="checkbox">
                <Input
                    type="checkbox"
                    value={
                        isPrivateParcel && option.name === "notification"
                            ? true
                            : option.value
                    }
                    onChange={(value) =>
                        updateAddition({
                            name: option.name,
                            value: value,
                            parameters: getDefaultParameters(option),
                        })
                    }
                    disabled={isPrivateParcel && option.name === "notification"}
                />
            </div>
            <div className="text">
                <div className="title">
                    {getOptionTitle(option.name) || option.prettyName}
                </div>
                <div className="description">
                    {getOptionSubTitle(option.name) || option.desc}
                </div>
                <div className="my-3">
                    {renderServiceSpecificOptions(option)}
                </div>
            </div>
            <div className="price">{getPrice(option)}</div>
        </div>
    );
};

export default AdditionalOption;
