export const useLocalStorage = (key = "") => {
    const setItem = (value = "") => {
        try {
            localStorage.setItem(key, JSON.stringify(value));
        } catch (error) {}
    };

    const getItem = () => {
        try {
            const item = localStorage.getItem(key);
            return item ? JSON.parse(item) : undefined;
        } catch (error) {}
    };

    const removeItem = () => {
        try {
            localStorage.removeItem(key);
        } catch (error) {}
    };

    return { setItem, getItem, removeItem };
};
