import Context from "context/Global";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import styled from "styled-components/macro";
import Message from "./Message";
import { useLocalStorage } from "hooks/useLocalStorage";

let Container = styled.div`
    margin-top: auto;

    > .message {
        padding: 4px 8px;
        background: var(--color-good);

        &.is-error {
            background: var(--color-bad);
        }

        &.is-minimized {
            > .title > .resize {
                border: 2px solid #fff;
                height: 4px;
                background: #fff;
            }
        }

        > .title {
            height: 20px;
            display: flex;
            align-items: center;
            font-weight: bold;
            display: flex;
            justify-content: space-between;

            > .resize {
                width: 20px;
                height: 16px;
                border: 3px solid #fff;
                box-sizing: border-box;
                background: transparent;
                cursor: pointer;
            }
        }
    }
`;

function Component(props) {
    const context = useContext(Context);
    const [messages, setMessages] = useState([]);
    const { getItem, setItem } = useLocalStorage("readMessages");
    const [readMessages, setReadMessages] = useState(getItem() || []);

    useEffect(() => {
        if (context.socket) {
            context.socket.emit("getPortalMessages", setMessages);
            context.socket.on("onPortalMessageCreated", (message) => {
                setMessages((messages) => {
                    message.new = true;
                    return [].concat(messages).concat(message);
                });
            });
            context.socket.on("onPortalMessageUpdated", (message) => {
                setMessages((messages) => {
                    return messages.map((msg) => {
                        if (msg.id === message.id) {
                            return message;
                        }
                        return msg;
                    });
                });
                setReadMessages((readMessages) => {
                    return readMessages.filter((id) => id !== message.id);
                });
            });
            context.socket.on("onPortalMessageDeleted", (messageId) => {
                setMessages((messages) => {
                    return messages.filter((msg) => {
                        return msg.id !== messageId;
                    });
                });
                setReadMessages((readMessages) => {
                    return readMessages.filter((id) => id !== messageId);
                });
            });

            return () => {
                context.socket.off("portalMessages");
                context.socket.off("newPortalMessage");
            };
        }
    }, [context.socket]);

    useEffect(() => {
        setItem(readMessages);
    }, [readMessages]);

    const filteredMessages = messages.filter((message) => {
        return (
            (!message.start || moment(message.start).diff(moment()) < 0) &&
            (!message.end ||
                moment(message.end).add(1, "day").diff(moment()) > 0)
        );
    });

    const renderMessages = () => {
        return filteredMessages.map((message) => {
            const read = readMessages.includes(message.id);
            return (
                <Message
                    key={message.id}
                    message={message}
                    read={read}
                    readMessages={readMessages}
                    setReadMessages={setReadMessages}
                />
            );
        });
    };

    return <Container>{renderMessages()}</Container>;
}

export default Component;
