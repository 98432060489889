import DisplayField from "../../../Components/DisplayField";

function VirtueMartStepTwo({ configuration, activateExtension }) {
    return (
        <div>
            <h3 className="text-2xl font-semibold mb-8">
                Steg 2 -{" "}
                <span className="text-lg font-medium text-accent">
                    {!configuration.apiKey
                        ? "Aktivera integration"
                        : "Konfigurera åtkomst"}
                </span>
            </h3>

            <div className="flex items-start">
                <div className="flex flex-col space-y-8 w-1/2">
                    <ul className="text-md font-medium list-disc list-outside pl-6 space-y-4">
                        {!configuration.apiKey ? (
                            <>
                                <li>
                                    För att få åtkomst till din API-nyckel och
                                    slutföra integrationen, behöver du aktivera
                                    den genom att klicka på knappen nedan.
                                </li>
                                <button
                                    className="c-button c-button--raised mt-4 w-60"
                                    onClick={activateExtension}
                                >
                                    Aktivera Integration
                                </button>
                            </>
                        ) : (
                            <>
                                <li>
                                    Navigera till "Components → Zendr →
                                    Configuration" i huvudmenyn.
                                </li>
                                <li>
                                    Kopiera och klistra in följande API-nyckel i
                                    fältet "Access Token":
                                </li>
                                <DisplayField
                                    value={configuration.apiKey}
                                    copyToClipboard={true}
                                />
                                <li>
                                    Välj om ni vill använda en standardvikt för
                                    ordrar.
                                </li>
                                <li>
                                    Välj om ni vill använda en standard
                                    godsbeskrivning för fraktsedlar.
                                </li>
                                <li>Spara era inställningar i VirtueMart.</li>
                                <li>Fortsätt till nästa steg.</li>
                            </>
                        )}
                    </ul>
                </div>

                {configuration.apiKey && (
                    <div className="ml-10 mr-10 space-y-6">
                        <img
                            src={`/images/extensions/virtuemart/access.png`}
                            alt="VirtueMart inställningar"
                            className="object-contain w-[32rem] h-[32rem] max-w-2xl"
                        />
                    </div>
                )}
            </div>
        </div>
    );
}

export default VirtueMartStepTwo;
