import HelpTextIcon from "../../../Components/InformationIcon";
import React from "react";
import { ReferenceOptions } from "../index";

function FortnoxStepOne({ configuration, handleChange, handleCheckboxChange }) {
    return (
        <div>
            <h3 className="text-2xl font-semibold mb-8">
                Steg 1 -{" "}
                <span className="text-lg font-medium text-accent">
                    Leveranskonfiguration
                </span>
            </h3>

            <div className="flex items-start">
                <div className="flex flex-col space-y-16 w-1/2">
                    <p>
                        Börja med att konfigurera vilka uppgifter som ska hämtas
                        från era Fortnox ordrar.
                    </p>

                    <div>
                        <div className="flex items-start">
                            <label
                                htmlFor="deliveryOptions"
                                className="block font-medium"
                            >
                                Välj mottagaradress
                            </label>
                            <HelpTextIcon infoText="Välj varifrån mottagarens leveransadress ska hämtas i Fortnox ordern." />
                        </div>
                        <select
                            name="deliveryOptions"
                            value={configuration.deliveryOptions}
                            onChange={handleChange}
                            className="w-2/3 p-2 h-10 border border-gray-300 rounded-md mt-2"
                            required
                        >
                            <option value="" disabled hidden>
                                Välj ett alternativ
                            </option>
                            <option value="CustomerDetails">
                                Kunduppgifter
                            </option>
                            <option value="DeliveryDetails">
                                Leveransuppgifter
                            </option>
                        </select>
                    </div>

                    <div>
                        <div className="flex items-start">
                            <label
                                htmlFor="packageDescription"
                                className="block font-medium"
                            >
                                Välj godsbeskrivning
                            </label>
                            <HelpTextIcon infoText="Välj mellan artikelnummer och benämning för godsbeskrivningen som hämtas från första artikeln i ordern." />
                        </div>
                        <select
                            name="packageDescription"
                            value={configuration.packageDescription}
                            onChange={handleChange}
                            className="w-2/3 p-2 h-10 border border-gray-300 rounded-md mt-2"
                            required
                        >
                            <option value="" disabled hidden>
                                Välj ett alternativ
                            </option>
                            <option value="ArticleNumber">Artikelnummer</option>
                            <option value="Name">Benämning</option>
                        </select>
                    </div>

                    <div>
                        <div className="flex items-start mb-2">
                            <label className="block font-medium">
                                Välj värde för Referens
                            </label>
                            <HelpTextIcon infoText="Välj ett eller flera alternativ för vilken referens ni önskar hämta från ordern i Fortnox.<br/> Exempelvis: 75 - 12345 - John Doe" />
                        </div>
                        <div className="flex flex-col gap-2">
                            {ReferenceOptions.map((option) => (
                                <label
                                    key={option.value}
                                    className="flex items-center space-x-2 font-medium ml-2"
                                >
                                    <input
                                        type="checkbox"
                                        name="reference"
                                        value={option.value}
                                        checked={configuration.reference?.includes(
                                            option.value,
                                        )}
                                        onChange={(e) =>
                                            handleCheckboxChange(e, "reference")
                                        }
                                        className="form-checkbox w-5 h-5"
                                        style={{
                                            accentColor: "rgba(76, 80, 175, 1)",
                                        }}
                                    />
                                    <span>{option.label}</span>
                                </label>
                            ))}
                        </div>
                    </div>
                </div>

                <div className="ml-10 mt-10 mr-10 space-y-10">
                    <img
                        src={`/images/extensions/fortnox/deliveryoptions.png`}
                        alt="Leveransadress"
                        className="object-contain w-[24rem] h-[24rem] max-w-2xl border-1"
                    />
                    <img
                        src={`/images/extensions/fortnox/goodsdescription.png`}
                        alt="Godsbeskrivning"
                        className="object-contain w-[24rem] h-[24rem] max-w-2xl border-1"
                    />
                    <img
                        src={`/images/extensions/fortnox/reference.png`}
                        alt="Referens"
                        className="object-contain w-[24rem] h-[24rem] max-w-2xl border-1"
                    />
                </div>
            </div>
        </div>
    );
}

export default FortnoxStepOne;
