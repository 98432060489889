import { getDeliveryDateInformation } from "utils/dateHelpers";
import DynamicQuote from "./Dynamic";
const Quotation = (props) => {
    const { deliveryDate, deliveryDateInterval } = getDeliveryDateInformation(
        props.data.deliveryDate,
        props.data.deliveryDateInterval,
    );
    return (
        <DynamicQuote
            service={props.data.service}
            route={props.order.route}
            deliveryDate={deliveryDate}
            deliveryDateInterval={deliveryDateInterval}
            pickupDate={props.data.pickupDate}
            quotation={props.data}
            errorMessages={props.data.errorMessages}
            warningMessages={props.data.warningMessages}
            informationMessages={props.data.informationMessages}
            receiverPays={props.receiverPays}
            price={props.data.price}
            forwarding={props.data.forwarding}
            onSetDap={props.onSetDap}
            invalidParams={props.invalidParams}
        />
    );
};

export default Quotation;
