import React, { useEffect, useState } from "react";
import HeaderSection from "./Components/HeaderSection";
import Api from "../../services/Api/Api";
import ExtensionImage from "./Components/ExtensionImage";
import ZendrApiCardSection from "./Components/ZendrApiCardSection";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";
import Loader from "../../components/Loader/Loader";

const CreateExtensionPage = () => {
    const [supportedExtensions, setSupportedExtensions] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(true);
        async function fetchSupportedExtensions() {
            try {
                const extensions = await Api.getIntegrations();
                setSupportedExtensions(extensions);
            } catch (error) {
                toast.error("Misslyckades att visa integrationer just nu");
            } finally {
                setLoading(false);
            }
        }
        fetchSupportedExtensions();
    }, []);

    if (loading) {
        return <Loader />;
    }

    return (
        <div className="flex flex-col h-screen">
            <div className="flex-grow mt-10 ml-20 mr-20 space-y-8">
                <HeaderSection
                    title="Lägg till ny integration"
                    description={
                        <>
                            Välj den integration du vill lägga till.
                            <br /> Genom att integrera med någon av våra
                            samarbetspartners kan dina ordrar importeras
                            automatiskt till Zendr-Portalen, och på så sätt
                            möjliggörs en enklare transport-bokning.
                        </>
                    }
                />

                <div className="space-y-14">
                    <Link to="/extensions/create/zendr" className="integration">
                        <ZendrApiCardSection />
                    </Link>
                    <div className="flex flex-wrap gap-10">
                        {supportedExtensions.map(
                            (extension) =>
                                extension.name !== "Zendr" && (
                                    <Link
                                        key={extension.id}
                                        to={`/extensions/create/${extension.name
                                            .toLowerCase()
                                            .replace(/[^A-Za-z0-9]+/gm, "-")}`}
                                    >
                                        <ExtensionImage type={extension.name} />
                                    </Link>
                                ),
                        )}
                    </div>
                </div>
            </div>

            <div className="mt-auto ml-20 mb-20">
                <HeaderSection
                    title="Vad saknar du här?"
                    description={
                        <>
                            Vi får gärna förslag på vad för integrationer just
                            ni vill använda.
                            <br />
                            Skicka iväg ett mail till{" "}
                            <a href="mailto:extensions@zendr.se">
                                extensions@zendr.se
                            </a>
                            .
                        </>
                    }
                />
            </div>
        </div>
    );
};

export default CreateExtensionPage;
