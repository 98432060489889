import { BATCH_ORDER, DHLPACKAGE } from "constants/batch/batchConstants";
import { POSTNORD } from "constants/carriers/postNord";
import { euMemberList } from "constants/countryList";
import { UPS_ACCESS_POINT } from "helpers/UPS";
import { isEqual } from "lodash";

export const getObjectDiff = (obj1, obj2) => {
    if (!obj1 || !obj2) {
        return [];
    }

    return Object.keys(obj1).reduce((result, key) => {
        if (!obj2.hasOwnProperty(key)) {
            result.push(key);
        } else if (isEqual(obj1[key], obj2[key])) {
            const resultKeyIndex = result.indexOf(key);

            result.splice(resultKeyIndex, 1);
        }
        return result;
    }, Object.keys(obj2));
};

export const mustRevalidate = (order, orderToUpdate) => {
    const revalidatePackages = !isEqual(
        orderToUpdate.data.packages?.map((pkg) => {
            return {
                height: pkg.height,
                length: pkg.length,
                width: pkg.width,
                weight: pkg.weight,
                stackable: pkg.stackable,
                type: pkg.type,
            };
        }),
        order.data.packages?.map((pkg) => {
            return {
                height: pkg.height,
                length: pkg.length,
                width: pkg.width,
                weight: pkg.weight,
                stackable: pkg.stackable,
                type: pkg.type,
            };
        }),
    );

    const revalidateSender = revalidateSenderReceiver(
        orderToUpdate.data.sender,
        order.data.sender,
    );
    const revalidateReceiver = revalidateSenderReceiver(
        orderToUpdate.data.receiver,
        order.data.receiver,
    );

    const revalidateOtherPickup = () => {
        const otherPickupWasUpdated = revalidateSenderReceiver(
            orderToUpdate.data?.sender?.pickup,
            order.data?.sender?.pickup,
        );

        const otherPickupRemoved = !orderToUpdate.data?.sender?.pickup;
        const otherPickupAdded = !order.data?.sender?.pickup;

        if (otherPickupRemoved && otherPickupAdded) {
            return false;
        }

        if (otherPickupWasUpdated || otherPickupRemoved || otherPickupAdded) {
            return true;
        }

        return false;
    };

    return (
        revalidatePackages ||
        revalidateSender ||
        revalidateReceiver ||
        revalidateOtherPickup()
    );
};

export const revalidateSenderReceiver = (newOrder, prevOrder) => {
    const diff = getObjectDiff(newOrder, prevOrder);

    return [
        "postalCode",
        "postalTown",
        "addressLine1",
        "addressLine2",
        "addressLine3",
        "countryCode",
    ].some((item) => diff.includes(item));
};

export const sortQuotesByCheapest = (quotes) => {
    return quotes.sort((a, b) => {
        return a?.price?.total - b?.price?.total;
    });
};

export const getStateStyle = (state, error) => {
    switch (state) {
        case BATCH_ORDER.State.Loading:
            return "bg-opacity-40 animate-pulse border-b border-secondary-400 relative";
        case BATCH_ORDER.State.Error:
            switch (error) {
                case BATCH_ORDER.Errors.Pickup.Failed:
                    return "border-b border-secondary-400 hover:bg-gray-50 cursor-pointer relative";
                default:
                    return "bg-red-200 border-b border-secondary-400 hover:bg-opacity-40 cursor-pointer relative";
            }
        case BATCH_ORDER.State.Success:
            return "border-b border-secondary-400 hover:bg-gray-50 cursor-pointer relative";
        default:
            return "border-b border-secondary-400 hover:bg-gray-50 cursor-pointer relative";
    }
};

export const formatDateString = (date) => {
    const dateObject = new Date(date).toLocaleDateString("sv-SE", {
        day: "numeric",
        month: "short",
    });

    return dateObject.charAt(0).toUpperCase() + dateObject.slice(1);
};

export const selectedOrdersHasNoSelectedQuotes = (selectedOrders) => {
    return selectedOrders?.some(
        (order) => order?.data.selectedQuote?.service?.name === undefined,
    );
};

export const validateBatchOrder = (order, bookBatchDirectWithoutQuote) => {
    if (order.state === BATCH_ORDER.State.Loading) {
        return false;
    }

    if (
        !order.data?.sender ||
        !order.data?.receiver ||
        !order.data?.packages ||
        !order.data?.information
    ) {
        return false;
    }

    if (
        !order.data.sender.contact?.name ||
        !order.data.sender.addressLine1 ||
        !order.data.sender.postalCode ||
        !order.data.sender.postalTown ||
        !order.data.sender.countryCode
    ) {
        if (order.data.private === false && !order.data.sender.contact?.name) {
            return false;
        }

        return false;
    }

    if (
        !order.data.receiver.contact?.name ||
        !order.data.receiver.addressLine1 ||
        !order.data.receiver.postalCode ||
        !order.data.receiver.postalTown ||
        !order.data.receiver.countryCode
    ) {
        if (
            (order.data.private === false &&
                !order.data.receiver?.contact?.company) ||
            (order.data.private === true && !order.data.receiver?.contact?.name)
        ) {
            return false;
        }
        return false;
    }

    if (!order.data.packages || order.data.packages.length === 0) {
        return false;
    }

    if (
        !order.data.packages.every(
            (pkg) =>
                pkg.weight > 0 &&
                pkg.height > 0 &&
                pkg.width > 0 &&
                pkg.length > 0 &&
                pkg.count > 0 &&
                (pkg.stackable === true || pkg.stackable === false),
        )
    ) {
        if (!bookBatchDirectWithoutQuote) {
            return false;
        } else {
            if (
                bookBatchDirectWithoutQuote &&
                !order.data.packages.every(
                    (pkg) => pkg.weight > 0 && pkg.count > 0,
                )
            ) {
                return false;
            }
        }
    }

    if (!order.data.information.description) {
        return false;
    }

    return true;
};

export const isValidDate = (date) => {
    if (date === "Invalid date") {
        return false;
    }

    if (Number(date) < Number(new Date())) {
        return false;
    }

    return true;
};

export const hasQuotesExpired = (order) => {
    if (!order.data.requestedPickupDate) {
        return false;
    }

    if (!order.data.quotations) {
        return false;
    }

    if (!order.data.quotesExpireUTC) {
        return false;
    }
    const expireDate = new Date(order.data.quotesExpireUTC);
    const currentDate = new Date();

    if (expireDate < currentDate) {
        return true;
    } else {
        return false;
    }
};

export const isObjectEmpty = (obj) => {
    if (obj === undefined || obj === null) {
        return true;
    }

    return Object.keys(obj)?.length === 0;
};

export const hasCheaperOptions = (order) => {
    if (!order) return false;

    const isImportedOrder = order.origin === BATCH_ORDER.Origins.ImportedOrder;
    const filteringEnabled = order.shippingRateFiltering;
    const shippingRateTitle =
        order.data.information.shippingRateTitle?.toLowerCase();
    const selectedServiceName =
        order.data.selectedQuote.service?.name?.toLowerCase();
    const selectedQuotePrice = order.data.selectedQuote?.price?.total;

    const hasCheaperQuotes = order.data.quotations?.some((quote) => {
        return (
            quote.price?.total !== null &&
            quote.price?.total < selectedQuotePrice
        );
    });

    return (
        isImportedOrder &&
        filteringEnabled &&
        shippingRateTitle &&
        selectedServiceName === shippingRateTitle &&
        hasCheaperQuotes
    );
};

export const weightOnlyShipment = (order) => {
    return order.data.packages?.every((pkg) => {
        return !pkg.length && !pkg.width && !pkg.height;
    });
};

export const directBookingRestrictions = (order, service) =>
    order.data?.sender?.countryCode === "SE" &&
    order.data.receiver?.countryCode === "SE" &&
    order.data.packages?.every((pkg) => {
        return (
            pkg.type === "package" &&
            pkg.weight <= (service === DHLPACKAGE ? 35 : 20) &&
            !pkg.length &&
            !pkg.width &&
            !pkg.height
        );
    }) &&
    order.data.packages.reduce((acc, curr) => acc + curr.count, 0) <=
        (service === DHLPACKAGE ? 150 : 20);

export const isEligibleForDirectPackageBooking = (
    mode,
    order,
    selectedOrders,
) =>
    mode === "single"
        ? directBookingRestrictions(order, DHLPACKAGE)
        : selectedOrders?.every((selectedOrder) => {
              return directBookingRestrictions(selectedOrder, DHLPACKAGE);
          });

export const isEligibleForDirectServicePointBooking = (
    mode,
    order,
    selectedOrders,
) =>
    mode === "single"
        ? directBookingRestrictions(order, !DHLPACKAGE)
        : selectedOrders?.every((order) => {
              return directBookingRestrictions(order, !DHLPACKAGE);
          });

export const serviceForCustomer = (service, customerProducts) => {
    const customerProduct = customerProducts?.find((product) => {
        if (service === DHLPACKAGE) {
            return (
                (product.name === "DHL Paket2" && product.enabled === true) ||
                (product.name === "DHL Paket" && product.enabled === true)
            );
        } else {
            return (
                (product.name === "DHL ServicePoint" &&
                    product.enabled === true) ||
                (product.name === "DHL ServicePoint2" &&
                    product.enabled === true)
            );
        }
    });

    return {
        service: {
            name: "dhl",
            productCode: customerProduct?.name?.includes("Service")
                ? "DHLServicePointB2C"
                : customerProduct?.name?.replace(/\s+/g, ""),
            priceCode: customerProduct?.name,
            type: null,
        },
        price: {},
    };
};

export const isServicePoint = (service) => {
    if (
        (service.productCode?.includes("Point") &&
            !service.productCode?.includes("Return")) ||
        service.productCode === UPS_ACCESS_POINT ||
        service.productCode === POSTNORD.services.mypack_collect
    ) {
        return true;
    }

    if (
        service.productCode?.includes("DHL Parcel Connect") &&
        !service.productCode?.includes("Return")
    ) {
        return true;
    }
};

export const checkIfNonEU = (order) => {
    if (
        !order?.data.receiver?.countryCode ||
        !order?.data.sender?.countryCode
    ) {
        return false;
    }

    return (
        !euMemberList.includes(order?.data.receiver?.countryCode) ||
        !euMemberList.includes(order?.data.sender?.countryCode)
    );
};

export const selectedOrdersHaveInvalidFields = (selectedOrders, settings) => {
    if (!selectedOrdersAreLoading(selectedOrders)) {
        return selectedOrders?.some(
            (order) =>
                !validateBatchOrder(
                    order,
                    settings.bookBatchDirectWithoutQuote,
                ),
        );
    }
};

export const selectedOrdersAreLoading = (selectedOrders) => {
    return selectedOrders?.some((order) => order.state === "loading");
};

export const selectedOrdersHaveExpiredQuotes = (selectedOrders) => {
    return selectedOrders?.some((order) => order.quotesExpired);
};

export const selectedOrdersHasNonEUAddress = (selectedOrders) => {
    return selectedOrders?.some((order) => checkIfNonEU(order));
};

export const selectedOrdersHaveDiffrentPickupDates = (selectedOrders) => {
    return selectedOrders?.some(
        (order) =>
            order.data.selectedQuote?.pickupDate &&
            order.data.selectedQuote?.pickupDate !==
                selectedOrders[0].data.selectedQuote?.pickupDate,
    );
};
