import { handleDownloadS3 } from "../../../../../utils/s3";

const fetchVismaExtensionInstallerVersion = async () => {
    const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/s3/integration?objectKey=visma/release.txt`,
    );
    return (await response.text()).trim();
};

export const fetchVismaExtensionInstaller = async () => {
    const filename = await fetchVismaExtensionInstallerVersion();
    handleDownloadS3({
        url: `${
            process.env.REACT_APP_API_URL
        }/api/s3/integration?objectKey=${encodeURIComponent(
            `visma/${filename}`,
        )}`,
        desiredFileName: filename,
    });
};
