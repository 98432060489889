import React, { useContext, useEffect, useState } from "react";
import Integrations from "../../../../constants/integrations";
import ExtensionCard from "../../Components/ExtensionCard";
import StepNavigation from "../../Components/StepNavigation";
import Api from "../../../../services/Api/Api";
import toast from "react-hot-toast";
import Context from "../../../../context/Global";
import FortnoxStepOne from "./Steps/FortnoxStepOne";
import IntegrationsTokenLifespans from "../../../../constants/integrations/IntegrationsTokenLifespans";
import FortnoxStepTwo from "./Steps/FortnoxStepTwo";
import FortnoxStepThree from "./Steps/FortnoxStepThree";
import FortnoxStepFour from "./Steps/FortnoxStepFour";
import { updateCustomerIntegrationsList } from "../../utils/authUtils";

export const FortnoxTriggerStatuses = [
    { value: "saved", title: "Sparad" },
    { value: "sent", title: "Skickad" },
    { value: "warehouseReady", title: "Lagerhantering klar" },
    { value: "invoiced", title: "Fakturerad" },
];

export const ReferenceOptions = [
    { value: "OrderNumber", label: "Ordernummer" },
    { value: "YourOrderNumber", label: "Ert ordernummer" },
    { value: "YourReference", label: "Er referens" },
];

function CreateFortnoxExtension({ history }) {
    const context = useContext(Context);
    const [currentStep, setCurrentStep] = useState(1);

    const [configuration, setConfiguration] = useState({
        authorizationCode: "",
        deliveryOptions: "",
        packageDescription: "",
        reference: "",
        requireOrderValue: "",
        transferTriggerStatus: "",
        includeInvoicedOrders: "",
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setConfiguration((prevConf) => ({
            ...prevConf,
            [name]: value === "true" ? true : value === "false" ? false : value,
        }));
    };

    function handleCheckboxChange(event, field) {
        const { value, checked } = event.target;

        setConfiguration((prevConf) => {
            const updatedValues = checked
                ? [...prevConf[field], value]
                : prevConf[field].filter((item) => item !== value);

            return {
                ...prevConf,
                [field]: updatedValues,
            };
        });
    }

    const handleSubmit = async () => {
        try {
            const data = {
                type: "Fortnox",
                authorizationCode: configuration.authorizationCode,
                refreshTokenExpiration:
                    IntegrationsTokenLifespans.FORNTOX_REFRESH_TOKEN_LIFESPAN,
                settings: {
                    deliveryOptions: configuration.deliveryOptions,
                    packageDescription: configuration.packageDescription,
                    reference: configuration.reference,
                    transferTriggerStatus: configuration.transferTriggerStatus,
                    requireOrderValue: configuration.requireOrderValue,
                    includeInvoicedOrders: configuration.includeInvoicedOrders,
                },
            };

            const response = await Api.createCustomerIntegration({
                customerId: context.user.customer.id,
                data,
            });

            updateCustomerIntegrationsList(context, response.id);

            history.push(`/extensions/${response.id}`);
        } catch (error) {
            toast.error(
                "Misslyckades med att skapa integrationen, kontrollera uppgifterna och försök igen!",
            );
        }
    };

    const steps = [
        {
            component: (
                <FortnoxStepOne
                    configuration={configuration}
                    handleChange={handleChange}
                    handleCheckboxChange={handleCheckboxChange}
                />
            ),
            isValid: () =>
                configuration.deliveryOptions !== "" &&
                configuration.packageDescription !== "",
        },
        {
            component: (
                <FortnoxStepTwo
                    configuration={configuration}
                    handleChange={handleChange}
                />
            ),
            isValid: () =>
                configuration.requireOrderValue !== "" &&
                configuration.transferTriggerStatus !== "" &&
                configuration.includeInvoicedOrders !== "",
        },
        {
            component: (
                <FortnoxStepThree
                    configuration={configuration}
                    handleChange={handleChange}
                />
            ),
            isValid: () => configuration.authorizationCode !== "",
        },
        {
            component: <FortnoxStepFour configuration={configuration} />,
            isValid: () => true,
        },
    ];

    const currentStepComponent = steps[currentStep - 1].component;

    return (
        <div className="min-h-full flex flex-col justify-between ml-20 pt-10">
            <div>
                <ExtensionCard extension={{ type: Integrations.FORTNOX }}>
                    <h1 className="text-2xl font-bold">Lägg till Fortnox</h1>
                    <p className="text-base font-medium mt-2">
                        För att kunna aktivera denna integration behöver du
                        följa stegen som beskrivs nedan.
                    </p>
                </ExtensionCard>
            </div>

            <div className="flex-grow flex">
                <div className="max-w w-full pt-10">{currentStepComponent}</div>
            </div>

            <div className="pb-12">
                <StepNavigation
                    currentStep={currentStep}
                    totalSteps={steps.length}
                    onStepChanged={setCurrentStep}
                    isCurrentStepValid={steps[currentStep - 1].isValid()}
                    onSubmit={handleSubmit}
                />
            </div>
        </div>
    );
}

export default CreateFortnoxExtension;
