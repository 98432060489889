import { handleDownloadS3 } from "../../../../../utils/s3";

function VirtueMartStepOne() {
    return (
        <div>
            <h3 className="text-2xl font-semibold mb-8">
                Steg 1 -{" "}
                <span className="text-lg font-medium text-accent">
                    Inställningar
                </span>
            </h3>

            <div className="flex items-start">
                <div className="flex flex-col space-y-8 w-1/2">
                    <ul className="text-md font-medium list-disc list-outside pl-6 space-y-4">
                        <li>Logga in på ditt Joomla-konto.</li>
                        <li>
                            Navigera till "Extensions → Manage → Install" i
                            huvudmenyn.
                        </li>
                        <li>
                            Välj alternativet "Upload Package File" i den nya
                            vyn.
                        </li>
                        <li>
                            Klicka på länken nedan för att ladda ner
                            installationsfilen för Zendr-modulen:
                        </li>
                        <a
                            onClick={() =>
                                handleDownloadS3({
                                    url: `${
                                        process.env.REACT_APP_API_URL
                                    }/api/s3/integration?objectKey=${encodeURIComponent(
                                        `joomla/zendr-joomla_${process.env.REACT_APP_JOOMLA_LATEST_VERSION}.zip`,
                                    )}`,
                                    desiredFileName: `zendr-joomla_${process.env.REACT_APP_JOOMLA_LATEST_VERSION}.zip`,
                                })
                            }
                        >
                            <p className="text-blue-700 cursor-pointer underline">
                                Zendr-Joomla-module.zip
                            </p>
                        </a>
                        <li>
                            Dra och släpp den nedladdade filen i Joomla-vyn för
                            att installera modulen.
                        </li>
                        <li>
                            När installationen är slutförd, fortsätt till nästa
                            steg.
                        </li>
                    </ul>
                </div>

                <div className="ml-10 mr-10 space-y-6">
                    <img
                        src={`/images/extensions/virtuemart/installation.png`}
                        alt="VirtueMart Installation"
                        className="object-contain w-[32rem] h-[32rem] max-w-2xl"
                    />
                </div>
            </div>
        </div>
    );
}

export default VirtueMartStepOne;
