export const getDeliveryDateInformation = (
    deliveryDate,
    deliveryDateInterval,
) => {
    let date = null;
    let interval = deliveryDateInterval;

    if (deliveryDate) {
        const regexResult = /INTERVAL_(\d+)_(\d+)/.exec(deliveryDate);
        if (regexResult?.length >= 3) {
            interval = {
                min: Number(regexResult[1]),
                max: Number(regexResult[2]),
            };
        } else {
            date = deliveryDate;
        }
    }

    return {
        deliveryDate: date,
        deliveryDateInterval: interval,
    };
};
