/**
 * Check if a postal code is a valid Stockholm postal code.
 * @param {string} postalCode - The postal code to check.
 * @param {string} countryCode - The country code of the postal code in ISO 3166 format.
 * @returns {boolean} True if the postal code is a valid Stockholm postal code, false otherwise.
 * @example
 * isStockholmPostalCode("123 45", "SE"); // true
 * isStockholmPostalCode("12345", "SE"); // true
 * isStockholmPostalCode("12345", "US"); // false
 */
export function isStockholmPostalCode(postalCode, countryCode) {
    if (countryCode !== "SE" || !postalCode) {
        return false;
    }

    const sanitizedPostalCode = postalCode.replace(/\D/g, "");

    if (sanitizedPostalCode.length !== 5) {
        return false;
    }

    const areaPrefix = parseInt(sanitizedPostalCode.substring(0, 2));

    return areaPrefix >= 10 && areaPrefix <= 19;
}
