import Context from "context/Global";
import { useContext, useEffect, useState } from "react";
import AnimateHeight from "react-animate-height";
import ReactMarkdown from "react-markdown";
import { Portal } from "react-portal";
import styled from "styled-components/macro";

let Container = styled.div`
    padding: 4px 8px;
    background: #6bacec;
    color: #fff;

    &.is-warning {
        background: var(--color-bad);
    }

    &.is-resolution {
        background: var(--color-good);
    }

    &.is-minimized {
        cursor: pointer;

        > .title > .resize {
            transform: rotate3d(1, 0, 0, 180deg);
        }

        > .text {
            height: 0;
        }
    }

    > .title {
        min-height: 20px;
        display: flex;
        align-items: center;
        font-weight: bold;
        display: flex;
        justify-content: space-between;

        > .resize {
            width: 20px;
            height: 20px;
            padding: 4px;
            box-sizing: border-box;
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            transition: all 0.5s ease;
            transform: rotate3d(1, 0, 0, 0deg);

            > svg {
                width: 100%;
                height: 100%;
            }
        }
    }
    .text {
    }

    .more {
        font-weight: bold;
        font-size: 0.8rem;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        cursor: pointer;

        > svg {
            height: 6px;
            margin-top: 2.5px;
            margin-left: -5px;
        }
    }
`;

let PortalContainer = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    color: #000;

    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.7);

    > .container {
        background: #fff;
        padding: 0 1rem;
        border-radius: 1rem;
        display: flex;
        flex-direction: column;
        width: 50vw;
        max-width: 95vw;

        height: 50vh;
        max-height: 95vh;
        background: #fff;

        > .title {
            font-size: 2rem;
        }

        > .content {
            overflow: auto;
        }

        > .buttons {
            margin-top: auto;
            display: flex;
            justify-content: space-between;
            padding: 1rem 0;
            align-items: center;

            > .text {
                color: #777;
            }
        }
    }
`;

function Component(props) {
    const context = useContext(Context);
    const [message, setMessage] = useState(null);
    const [showPopup, setShowPopup] = useState(false);
    const [isMinimized, setIsMinimized] = useState(props.read);

    useEffect(() => {
        setMessage(props.message);
    }, [props.message]);

    useEffect(() => {
        if (message?.new || !props.read) {
            setIsMinimized(false);
        }
    }, [message, props.read]);

    function toggleSize(ev) {
        if (!isMinimized && !props.read) {
            props.setReadMessages([message.id, ...props.readMessages]);
        }
        setIsMinimized((minimized) => !minimized);
        ev.stopPropagation();
    }

    function onClick() {
        if (isMinimized) {
            setIsMinimized(false);
        }
    }

    return (
        <Container
            className={`is-${message?.type.toLowerCase()} ${
                isMinimized && "is-minimized"
            }`}
            onClick={onClick}
        >
            <div className="title">
                {message?.title}
                <div className="resize" onClick={toggleSize}>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                    >
                        <path
                            fill="currentColor"
                            d="M0 7.33l2.829-2.83 9.175 9.339 9.167-9.339 2.829 2.83-11.996 12.17z"
                        />
                    </svg>
                </div>
            </div>
            <AnimateHeight height={isMinimized ? 0 : "auto"}>
                <div className="text">{message?.shortText}</div>
                {!!message?.longText && (
                    <div
                        className="more"
                        onClick={() => {
                            setShowPopup(true);
                        }}
                    >
                        Läs mer{" "}
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                        >
                            <path fill="currentColor" d="M21 12l-18 12v-24z" />
                        </svg>
                    </div>
                )}
            </AnimateHeight>
            {showPopup && (
                <Portal>
                    <PortalContainer>
                        <div className="container">
                            <div className="content">
                                <ReactMarkdown
                                    className="text"
                                    renderers={{
                                        link: (props) => (
                                            <a
                                                href={props.href}
                                                target="_blank"
                                            >
                                                {props.children}
                                            </a>
                                        ),
                                    }}
                                >
                                    {message?.longText}
                                </ReactMarkdown>
                            </div>
                            <div className="buttons">
                                <div className="text">
                                    Har du frågor är det bara att ringa oss på{" "}
                                    <a
                                        href={`tel:${context.user.customer.brand.supportPhoneNumber}`}
                                    >
                                        {
                                            context.user.customer.brand
                                                .supportPhoneNumber
                                        }
                                    </a>
                                </div>
                                <div
                                    className="c-button c-button--raised"
                                    onClick={() => setShowPopup(false)}
                                >
                                    Stäng
                                </div>
                            </div>
                        </div>
                    </PortalContainer>
                </Portal>
            )}
        </Container>
    );
}

export default Component;
